/* eslint-disable no-underscore-dangle */
import React from "react";
import { ListBasic } from "react-blueprint-library";
import AnnuityContractInfo from "./contractInfo";
import { usePolicyInfoHeaderProps } from "../hooks";
import IsTruthy from "../../../../common/logic/isTruthy";
import IsFalsy from "../../../../common/logic/isFalsy";
import { isDefined } from "../../../../../utils/evaluate";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../../common/loading";
import AnnuityFormsCenterLink from "../../../../home/annuityFormsCenterLink";
import {
  CONTRACT_NOT_IF_ALERT_TEXT,
  E_DELIVERY_BANNER_TEXT,
} from "../../../../../constants/ui";
import ChangeAddressLink from "../../../../home/changeAddressLink";
import FundTransferLink from "../../../../home/fundTransferLink";
import UserRegisteredSignifier from "../../../../common/userRegisteredSignifier/userRegisteredSignifier";
import InitiateDeathClaimLink from "../../../../home/initiateDeathClaimLink";
import DirectLinksLink from "../../../../home/directLinksLink";
import { DigitalRegistrationLink } from "../../../../home/digitalRegistrationLink";
import FillableFormLibraryLink from "../../../../home/fillableFormLibraryLink";

const AnnuityContractInfoHeader = (props) => {
  const {
    policyId,
    agreementRequest,
    billingRequest,
    producersRequest,
    openDrawer,
    unsupportedPolicy,
    annuityRequest,
    soaBillingRequest,
    fundTransferEligibilityRequest = {},
    serviceHistoryRequest,
    user,
    lineOfBusinessCode,
    deathClaimEligibilityRequest,
  } = props;

  const {
    styling: { badgeColor },
    data: {
      policyBasicInfo,
      lineOfBusiness,
      statusCode,
      policyOwners,
      email,
      policyInForce,
      trimmedContractNbr,
    },
    transactionLinkSetFunctions: {
      setShowChangeAddressLink,
      setShowAnnuityFormsCenterLink,
      setShowFundTransferLink,
      setShowInitiateDeathClaimLink,
      setShowDirectLinksLink,
      setShowFillableFormLibraryLink,
    },
    showTransactionLinkBox,
    showEDeliveryBanner,
  } = usePolicyInfoHeaderProps(props);

  const ownersList = policyOwners.map((customer, index) => {
    const { fullName } = customer;
    return (
      <div key={`${fullName}`}>
        <IsTruthy value={fullName} validationFunction={isDefined}>
          <h2 id={`policy_owner_${index}_name_and_signifier`}>
            <span
              id={`policy_owner_${index}_full_name`}
              className="mm-illustration-buffer"
            >
              {fullName}
            </span>{" "}
            <UserRegisteredSignifier
              customer={customer}
              isCalledFrom="PDP"
              indexNumber={index}
              lineOfBusinessCode={lineOfBusinessCode}
            />
          </h2>
          <div className="mm-sub-copy hide-print">
            <ListBasic variant="separated">
              <ListBasic.ListBasicItem>
                {" "}
                <button
                  id={`view_customer_${index}_details_btn`}
                  type="button"
                  className="btn btn-inline"
                  onClick={openDrawer(customer)}
                >
                  View Details
                </button>
              </ListBasic.ListBasicItem>
              <DigitalRegistrationLink
                agreementData={agreementRequest?.data}
                customer={customer}
                isCalledFrom="PDP"
                policyId={policyId}
                lineOfBusinessCode={lineOfBusinessCode}
              />
            </ListBasic>
          </div>
        </IsTruthy>
      </div>
    );
  });

  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.PARAGRAPH}
      errorType={errorTypes.NONE}
      unsupportedPolicy={unsupportedPolicy}
    >
      <IsTruthy value={showEDeliveryBanner}>
        <div
          id="eDelivey_Banner_ANN_pdp"
          className="alert alert-info"
          role="alert"
        >
          {E_DELIVERY_BANNER_TEXT}
        </div>
      </IsTruthy>
      <h1 id="policy_detail_title_value">
        {policyId} ({lineOfBusiness}){" "}
        <span
          id="policy_detail_status_value"
          className={`badge badge-pill ${badgeColor}`}
        >
          {statusCode}
        </span>
      </h1>
      <div className="row">
        <div className="col-lg-8">
          <LoadingSection
            request={fundTransferEligibilityRequest}
            loadingType={loadingTypes.PARAGRAPH}
            errorType={errorTypes.CHILDREN}
            unsupportedPolicy={unsupportedPolicy}
          >
            <LoadingSection
              request={annuityRequest}
              loadingType={loadingTypes.PARAGRAPH}
              errorType={errorTypes.CHILDREN}
              unsupportedPolicy={unsupportedPolicy}
            >
              <IsFalsy value={policyInForce}>
                <div className="alert alert-info mm-spacing" role="alert">
                  {CONTRACT_NOT_IF_ALERT_TEXT}
                </div>
              </IsFalsy>

              <IsTruthy
                value={policyOwners}
                validationFunction={(list) => list.length}
              >
                <p id="envision_owner_lbl" className="eyebrow">
                  OWNER(S)
                </p>
              </IsTruthy>
              {ownersList}
              <AnnuityContractInfo
                policyId={policyId}
                policyBasicInfo={policyBasicInfo}
                agreementData={agreementRequest.data}
                producerData={producersRequest.data}
                annuityRequest={annuityRequest}
                soaBillingRequest={soaBillingRequest}
              />
            </LoadingSection>
          </LoadingSection>
        </div>

        <IsTruthy value={showTransactionLinkBox}>
          <div id="quick_actions_desktop" className="col-lg-4">
            <LoadingSection
              request={fundTransferEligibilityRequest}
              loadingType={loadingTypes.PARAGRAPH}
              errorType={errorTypes.CHILDREN}
              unsupportedPolicy={unsupportedPolicy}
            >
              <LoadingSection
                request={annuityRequest}
                loadingType={loadingTypes.PARAGRAPH}
                errorType={errorTypes.CHILDREN}
                unsupportedPolicy={unsupportedPolicy}
              >
                <div className="card">
                  <div className="card-body">
                    <ul className="mm-list-group--quick-actions">
                      <IsTruthy value={policyInForce}>
                        <ChangeAddressLink
                          data={agreementRequest.data}
                          policyId={trimmedContractNbr}
                          userEmail={email}
                          cssClassName="btn btn-inline mm-spacing--none"
                          isCalledFrom="PDP"
                          target="_blank"
                          billingData={billingRequest.data}
                          annuityData={annuityRequest?.data}
                          isRenderAsListItem
                          setShowChangeAddressLink={setShowChangeAddressLink}
                        />
                      </IsTruthy>
                      <FundTransferLink
                        data={agreementRequest.data}
                        policyId={trimmedContractNbr}
                        userEmail={email}
                        tealiumCategory="PDP"
                        isRenderAsListItem
                        setShowFundTransferLink={setShowFundTransferLink}
                        fundTransferEligibilityData={
                          fundTransferEligibilityRequest?.data
                        }
                        producersData={producersRequest?.data}
                        user={user}
                      />
                      <AnnuityFormsCenterLink
                        agreementData={agreementRequest.data}
                        email={email}
                        policyId={policyId}
                        id="annuity_forms_center_link"
                        className="btn btn-inline mm-spacing--none"
                        tealiumCategory="PDP"
                        renderAsListItem
                        target="_blank"
                        setShowAnnuityFormsCenterLink={
                          setShowAnnuityFormsCenterLink
                        }
                      />
                      <InitiateDeathClaimLink
                        policyId={policyId}
                        userEmail={email}
                        cssClassName="btn btn-inline mm-spacing--none"
                        isCalledFrom="PDP"
                        agreementData={agreementRequest.data}
                        serviceHistoryRequest={serviceHistoryRequest}
                        deathClaimEligibilityData={
                          deathClaimEligibilityRequest?.data
                        }
                        isRenderAsListItem
                        setShowInitiateDeathClaimLink={
                          setShowInitiateDeathClaimLink
                        }
                      />
                      <FillableFormLibraryLink
                        agreementData={agreementRequest.data}
                        setShowFillableFormLibraryLink={
                          setShowFillableFormLibraryLink
                        }
                        policyId={policyId}
                        userEmail={email}
                        tealiumCategory="PDP"
                        isRenderAsListItem
                        cssClassName="btn btn-inline mm-spacing--none"
                      />
                      <DirectLinksLink
                        policyId={policyId}
                        userEmail={email}
                        cssClassName="btn btn-inline mm-spacing--none"
                        tealiumCategory="PDP"
                        data={agreementRequest.data}
                        isRenderAsListItem
                        setShowDirectLinksLink={setShowDirectLinksLink}
                      />
                    </ul>
                  </div>
                </div>
              </LoadingSection>
            </LoadingSection>
          </div>
        </IsTruthy>
      </div>
    </LoadingSection>
  );
};

export default React.memo(AnnuityContractInfoHeader);
