/* eslint-disable import/prefer-default-export */
export const DATA_UNAVAILABLE = "Unavailable";
export const NOT_APPLICABLE = "Not Applicable";
export const NOT_BILLED = "Not Billed";
export const AGREEMENT_NOT_FOUND = "Agreement not found";
export const AS_OF_DATE_UNAVAILABLE = "As of date is unavailable";
export const MassMutualRetirePayDescription =
  "MassMutual RetirePay guarantees that each Contract Year beginning with the Guaranteed Lifetime Withdrawal Date, you can receive guaranteed lifetime income equal to the Annual Lifetime Benefit Amount even if the Contract Value is reduced to zero as long as there is a positive Benefit Base.";
export const MassMutualRetirePayPrintDescription =
  "MassMutual RetirePay guarantees that each Contract Year beginning with the Guaranteed Lifetime Withdrawal Date, you can receive guaranteed lifetime income equal to the Annual Lifetime Benefit Amount even if your Contract Value is reduced to zero as long as there is a positive Benefit Base.";
export const ReturnofPurchasePaymentDeathBenefitDescription =
  "Return of Purchase Payment Death Benefit is the greater of contract value of the date we receive proof of death and election of payment method or total purchase payments adjusted for any withdrawals.";
export const ReturnofPurchasePaymentDeathBenefitPrintDescription =
  "Return of Purchase Payment Death Benefit is the greater of contract value of the date we receive proof of death and election of payment method or total purchase payments adjusted for any withdrawals.";
export const NursingHomeandHospitalWithdrawalBenefitRiderDescription =
  "This benefit allows the contract owner to make a withdrawal of some of or all of the Contract Value without incurring contingent deferred sales charges (CDSC).  Please refer to the owner’s contract or prospectus for more information.";
export const NursingHomeandHospitalWithdrawalBenefitRiderPrintDescription =
  "If you are admitted to a licensed nursing home facility, this benefit allows you to withdraw of some of or all of your Contract Value without incurring contingent deferred sales charges (CDSC).  Please refer to your contract or prospectus for more information.";
export const TerminalIllnessWithdrawalBenefitRiderDescription =
  "This benefit allows the contract owner to make a withdrawal of some of or all of the Contract Value without incurring contingent deferred sales charges.  We require written proof that contract owner (or an Annuitant, if the Owner is a non-natural person) are terminally ill by a state licensed medical practitioner.  Please refer to the owner’s contract or prospectus for more information.";
export const TerminalIllnessWithdrawalBenefitRiderPrintDescription =
  "This benefit allows you to withdraw some of or all of your Contract Value without incurring contingent deferred sales charges.  We require written proof that you (or an Annuitant, if the Owner is a non-natural person) are terminally ill by a state licensed medical practitioner.  Please refer to your contract or prospectus for more information.";
export const TRANSACTIONS_DISABLED_MESSAGE =
  "Transactions for home office users are for informational purposes only. You will be able to navigate through the flow but not be able to submit the request.";
export const LOADING_ICON_CUSTOM_MESSAGE =
  "Something went wrong on our end. Let's try that again.";
export const PROCESSING_ERROR_MESSAGE =
  "There was an error processing your request; please try again later.";
export const SELECTED_DECEDENT_ERROR_MESSAGE =
  "Information for the decedent you’ve selected is not available at this time. Please try again later.";
export const NO_RIDER_ALERT_TEXT =
  "There is no rider information available online for this contract. If you have any questions, contact our Service Center at ";
export const NO_SERVICE_HISTORY_ALERT_TEXTS = {
  contract:
    "There is no service history available online for this contract. If you have any questions, contact our Service Center at ",
  policy:
    "There is no service history available online for this policy. If you have any questions, contact our Service Center at ",
};
export const NO_DOCUMENTS_ALERT_TEXTS = {
  contract:
    "There are no documents available online for this contract. If you have any questions, contact our Service Center at ",
  policy:
    "There are no documents available online for this policy. If you have any questions, contact our Service Center at ",
};
export const NO_INVESTMENT_INFO_ALERT_TEXT =
  "There is no investment information available online for this contract. If you have any questions, contact our Service Center at ";
export const NO_BENEFICIARY_INFO_ALERT_TEXT =
  "There is no beneficiary information available online for this contract. If you have any questions, contact our Service Center at ";
export const DI_OCCUPATION_CODE_TOOL_TIP_FOR_RADIOUS_CHOICE =
  "The occupation class modifiers create additional price points in between the existing occupation classes. They allow occupations to be more appropriately classified on their expected morbidity risk.";
export const DI_OCCUPATION_CODE_TOOL_TIP_FOR_NON_RADIOUS_CHOICE =
  "A change in this would be in the event of the insured’s job duties changing. Increasing it is an increase in risk and requires underwriting, decreasing the premium. Example 3A to 4A. Decreasing it is a decrease in risk and requires no underwriting, increasing the premium. Example 4A to 3A.";
export const NO_COVERAGE_ALERT_TEXT =
  "There is no rider information available online for this policy. If you have any questions, contact our Service Center at ";
export const NO_LOAN_ALERT_TEXT =
  "There are no loans on this contract. If you have any questions, contact our Service Center at ";
export const NO_ACTIVE_LOAN_ALERT_TEXT =
  "There are no active loans on this contract.";
export const CONTRACT_NOT_IF_ALERT_TEXT =
  "This contract is not In Force and information is not available online for this contract.";
export const NO_FINANCIAL_HISTORY_ALERT_TEXTS = {
  contract:
    "Financial History is not supported for this contract. If you have any questions, contact our Service Center at ",
  policy:
    "Financial History is not supported for this policy. If you have any questions, contact our Service Center at ",
};
export const ADDRESS_INCOMPLETE_ERROR_MESSAGE =
  "Please enter a valid address, including using the word Apt/STE/Unit/Floor/Bldg if applicable, e.g. 10 Main St Apt 10. Don’t use symbols like #.";
export const ADDRESS_BUILDING_INCOMPLETE_ERROR_MESSAGE =
  "Please confirm your building number";
export const ADDRESS_INVALID_ERROR_MESSAGE =
  "Please enter a valid address including any Apt/STE/Unit/Floor/Bldg in the correct USPS format, e.g. Apt 10. Don’t use symbols like #.";
export const ADDRESS_INCOMPLETE_ERROR =
  "Please confirm your Apt/Ste/Unit Number";
export const MULTIPLE_ADDRESS_ERROR =
  "More than one address was returned based on address in request.";
export const GUARANTEE_PERIOD_ENDING_TEXT =
  "This policy's guaranteed level premium period is ending. Now that the guaranteed level premium period is ending, the premium will increase each year on the policy anniversary. If the policy is within its conversion period, the owner may have the option to convert to a permanent MassMutual life insurance policy.";
export const COVERPATH_MIGRATED_ALERT =
  "This is a Coverpath supported policy. As a result, certain policy details and transactions are not available. If you need any additional information, you may contact Coverpath Customer Service at ";
export const PREAUTH_TOTAL_DRAFT_AMOUNT_TOOLTIP_TEXT =
  "The Total Draft Amount is the total of the premiums of all the policies associated with this PAC account.";
export const LIFE_DI_GRACE_PERIOD_TOOLTIP_TEXT =
  "After this date policy remains in force if there is enough value to\n" +
  "cover amount due and the policy has automatic premium loan provision.\n" +
  "Should the policy not have enough value, it is lapsed.";
export const LIFE_GRACE_PERIOD_TOOLTIP_TEXT =
  "LIFE ONLY: For policies issued in the state of California, the client has 60 days from the premium due date or date indicated on PAC grace letter. During this 60-day grace period, the coverage is still in force, even though the premium has not been paid.";
export const LISR_UNAVAILABLE_VALUE_TOOLTIP_TEXT = `This information is currently unavailable. Please refer to the most recent bill in the Documents section, if available. Otherwise, contact the Service Center at <a href="tel:1-800-272-2216">1-800-272-2216</a>.`;
export const LISR_UNAVAILABLE_VALUE_PRINT_TEXT = `This information is currently unavailable. Please refer to the most recent bill in the Documents section, if available. Otherwise, contact the Service Center at 1-800-272-2216.`;
export const INITIATE_DEATH_CLAIM_DECEDENT_DISABILITY_POLICY_INSURED_MESSAGE =
  " If you believe the insured may have qualified for Disability benefits prior to death, please contact the Service Center at 1-800-272-2216, Monday through Friday, 8:00 AM - 8:00 PM ET.";
export const INITIATE_DEATH_CLAIM_BENE_MAX_COUNT_REACHED_MESSAGE =
  "You have added the maximum number of beneficiary contacts. You may use the additional comments section on the Review step to provide the Claim Examiner with more information.";
export const LISR_BILLED_PREMIUM_TEXT =
  "LISR Billed Premium Amount is included in the Billed Premium displayed above.";
export const INITIATE_DEATH_CLAIM_MAX_RECIPIENT_CONTACT_TEXT =
  "You have added the maximum number of recipients. You may use the additional comments section on the next step to provide the Claim Examiner with more information.";
export const WITHIN_ONE_YEAR_OF_ISSUE_DATE_TOOLTIP_TEXT =
  "Ineligible until after first anniversary.";
export const TOTAL_WITHDRAWAL_AMOUNT_TOOLTIP_TEXT =
  "This amount may only reflect the modal premium for the policy.  The amount withdrawn may be greater or less than this value due to loan interest or dividends.  Please refer to the bill in Documents for details.";
export const DEFAULT_404_ERROR_MESSAGE =
  "We can’t seem to find the page you’re looking for.";
export const ANNUAL_DIVIDEND_TOOLTIP_TEXT =
  "Cash value of the dividend earned on the policy anniversary.";
export const REQUEST_INFORMATION_TOOLTIP_TEXT =
  "The information is not available online or via the call center. Click to submit a request for this information.";
export const DIVIDEND_CASH_VALUE_ADDITIONS_TOOLTIP_TEXT =
  "Cash value of the Paid Up Additions (Dividends). The cash value is typically available to withdraw in cash or to make payments towards the policy premium.";
export const LOAN_RESTRICTED_SWL_POLICY_ERROR_MESSAGE =
  "This policy is currently ineligible to perform this transaction until after the first anniversary.";
export const STANDARD_ERROR_WITH_RETRY =
  "Something went wrong on our end. Let's try that again.";
export const ERROR_TRY_AGAIN_LATER_TEXT =
  "Something continues to be wrong on our end. Please try again later.";
export const NO_DISTRIBUTION_DISPLAYED_MESSAGE =
  "*If no distribution is displayed, the death benefit will be paid in equal shares to the surviving beneficiaries in that class.";
export const FREE_AMOUNT_NOT_APPLICABLE_TOOLTIP_TEXT =
  "Free Amount is not applicable when the Surrender Charge is $0.00.";
export const FREE_WITHDRAWAL_NOT_APPLICABLE_TOOLTIP_TEXT =
  "The Free Withdrawal is not applicable because the Contingent Deferred Sales Charge is $0.00.";
export const PREMIUM_FINANCE_IND_BANNER_TEXT =
  "This policy is identified as a premium finance policy.";
export const NOTIFICATIONS_ERROR_TEXT =
  "An error occurred while trying to load your notifications.";
export const STANDARD_ERROR_TEXT = "Sorry, something went wrong.";
export const AUTHORIZATION_CONFIRMATION_STATEMENT =
  "I confirm that the policy/contract owner has authorized me to submit this transaction request.";
export const ULTIMATE_FACE_AMOUNT_TOOLTIP =
  "This amount represents the Face Amount of the policy if the Supplemental Insurance Dividend (SID) is removed or nullified.";
export const E_DELIVERY_BANNER_TEXT =
  "If eligible, send the Paperless Preference Direct Link to your MassMutual.com registered clients and encourage them to opt in to Paperless Statements.";
