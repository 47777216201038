/* istanbul ignore file */
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

// Favicons
/* eslint-disable import/no-extraneous-dependencies */
import AppleTouchIcon from "blueprint-css/images/favicons/apple-touch-icon.png";
import Favicon32 from "blueprint-css/images/favicons/favicon-32x32.png";
import Favicon16 from "blueprint-css/images/favicons/favicon-16x16.png";
import SafariPinnedTab from "blueprint-css/images/favicons/safari-pinned-tab.svg";
/* eslint-enable import/no-extraneous-dependencies */

import { SecureRoute } from "@okta/okta-react";
import { Helmet } from "react-helmet";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useGetUser } from "./hooks/okta";
import { useUserState, types as userStateTypes } from "./contexts/user";
import {
  useAgentProfileState,
  types as agentProfileStateTypes,
} from "./contexts/agentProfile";

import { getConfig, types as configTypes } from "./config";
import { ModalProvider } from "./contexts/modal";
import "./App.scss";

import SiteDown from "./components/common/errors/siteDown";

// Every route
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import UserAuthenticationCheck from "./components/layout/userAuthenticationCheck";
import DevMode from "./components/layout/devMode";

// Component Routes
import HomeOffice from "./components/home";
import LoggedOut from "./components/loggedOut";
import ContactUs from "./components/contactUs";
import LoginCallback from "./components/loginCallback";
import NoMatch from "./components/noMatch";
import PolicyDetails from "./components/policyDetails";
import PolicyPaymentOptions from "./components/policyDetails/pages/paymentOptions";
import PolicyFinancialHistory from "./components/policyDetails/pages/financialHistory";
import PolicyCashValues from "./components/policyDetails/pages/cashValues";
import PolicyRequestInformation from "./components/policyDetails/pages/requestInformation";
import PolicyDocuments from "./components/policyDetails/pages/documents";
import PolicyInitiateNewLoan from "./components/initiateNewLoan";
import PolicyLoanPayoffAsOfDate from "./components/policyDetails/pages/loanPayoffAsOfDate";
import PolicyServiceHistory from "./components/policyDetails/pages/viewServiceHistory";
import PolicyFunds from "./components/policyDetails/pages/funds";
import ChangeAddress from "./components/changeAddress";
import IsTruthy from "./components/common/logic/isTruthy";
import IsFalsy from "./components/common/logic/isFalsy";
import FourHundredFourError from "./components/common/errors/404";
import LdBannerContent from "./components/common/molecules/ldBannerContent";
import { useSiteDownProps } from "./hooks/helper";
import ChangeBillingFrequency from "./components/changeBillingFrequency";
import PolicyRestrictionCheck from "./components/layout/policyRestrictionCheck";
import FundTransfer from "./components/fundTransfer";
import {
  fireTealiumfromStoredObject,
  tealiumEventSource,
} from "./utils/tealium";
import LoginError from "./components/loginError";
import InitiateDeathClaim from "./components/initiateDeathClaim";
import DirectLinks from "./components/directLinks";
import AgentServiceTracking from "./components/notifications";
import DigitalRegistration from "./components/digitalRegistration";
import FillableForms from "./components/sendFillableForm";

function App() {
  const tealiumEnviroment = getConfig(configTypes.tealiumEnviroment);
  const baltoAppUrl = getConfig(configTypes.baltoAppUrl);
  const { siteDown } = useSiteDownProps();

  // tealium setup
  useEffect(() => {
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;
    const tealiumScript = document.createElement("script");
    tealiumScript.text = `(function(a,b,c,d){ a='//tags.tiqcdn.com/utag/massmutual/main/${tealiumEnviroment}/utag.js'; b=document; c='script'; d=b.createElement(c); d.src=a; d.type='text/java'+c; d.async=true; a=b.getElementsByTagName(c)[0]; a.parentNode.insertBefore(d,a); })();`;
    document.body.appendChild(tealiumScript);
    return () => {
      document.body.removeChild(tealiumScript);
    };
  }, [tealiumEnviroment]);
  if (window?.utag) {
    fireTealiumfromStoredObject();
  }

  const app = (
    <>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        <link rel="icon" type="image/png" href={Favicon32} sizes="32x32" />
        <link rel="icon" type="image/png" href={Favicon16} sizes="16x16" />
        <link rel="mask-icon" href={SafariPinnedTab} color="#5bbad5" />
        <script
          type="text/javascript"
          src={`//tags.tiqcdn.com/utag/massmutual/main/${tealiumEnviroment}/utag.sync.js`}
        />
        <script id="baltoApp" type="text/javascript" src={`${baltoAppUrl}`} />
      </Helmet>
      <IsTruthy value={siteDown}>
        <SecureRoute render={() => ComponentWithHeaderFooter(SiteDown)} />
      </IsTruthy>
      <IsFalsy value={siteDown}>
        <Switch>
          <SecureRoute
            path="/"
            exact
            render={() =>
              ComponentWithHeaderFooter(HomeOffice, {
                displayNotificationsDropdown: true,
                tealiumEventSourcePage: tealiumEventSource.LANDING_PAGE,
              })
            }
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey"
            render={() =>
              ComponentWithHeaderFooter(PolicyDetails, {
                displayDataIntegrityDropdown: true,
                displayNotificationsDropdown: true,
                tealiumEventSourcePage: tealiumEventSource.POLICY_DETAILS_PAGE,
              })
            }
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/paymentOptions"
            render={() => ComponentWithHeaderFooter(PolicyPaymentOptions)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/financialHistory"
            render={() =>
              ComponentWithHeaderFooter(PolicyFinancialHistory, {
                displayDataIntegrityDropdown: true,
              })
            }
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/cashValues"
            render={() => ComponentWithHeaderFooter(PolicyCashValues)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/documents"
            render={() =>
              ComponentWithHeaderFooter(PolicyDocuments, {
                displayDataIntegrityDropdown: true,
              })
            }
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/requestInformation"
            render={() => ComponentRender(PolicyRequestInformation)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/initiateNewLoan"
            render={() => ComponentRender(PolicyInitiateNewLoan)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/loanPayoffAsOfDate"
            render={() => ComponentWithHeaderFooter(PolicyLoanPayoffAsOfDate)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/viewServiceHistory"
            render={() =>
              ComponentWithHeaderFooter(PolicyServiceHistory, {
                displayDataIntegrityDropdown: true,
              })
            }
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/funds"
            render={() => ComponentWithHeaderFooter(PolicyFunds)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/changeAddress"
            render={() => ComponentRender(ChangeAddress)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/fundTransfer"
            render={() => ComponentRender(FundTransfer)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/sendDirectLinks"
            render={() => ComponentRender(DirectLinks)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/initiateDeathClaim"
            render={() => ComponentRender(InitiateDeathClaim)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/:memberId/digitalRegistration"
            render={() => ComponentRender(DigitalRegistration)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/changeBillingFrequency"
            render={() => ComponentRender(ChangeBillingFrequency)}
          />
          <SecureRoute
            exact
            path="/agentServiceTracking"
            render={() => ComponentWithHeaderFooter(AgentServiceTracking)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/sendFillableForm"
            render={() => ComponentRender(FillableForms)}
          />
          <SecureRoute
            exact
            path="/policyDetails/:agreementKey/*"
            render={() => ComponentWithHeaderFooter(FourHundredFourError)}
          />
          <SecureRoute
            exact
            path="/contact-us"
            render={() => ComponentWithHeaderFooter(ContactUs)}
          />
          <Route path="/implicit/callback" component={LoginCallback} />
          <Route path="/logged-out" component={LoggedOut} />
          <Route
            path="/loginError"
            render={() => ComponentWithHeaderFooterUnsecure(LoginError)}
          />
          <Route
            path="*"
            render={() => ComponentWithHeaderFooterUnsecure(NoMatch)}
          />
        </Switch>
      </IsFalsy>
    </>
  );
  return app;
}
const ComponentRender = (Component) => (
  <div>
    <div className="row">
      <DevMode />
      <UserAuthenticationCheck>
        <PolicyRestrictionCheck>
          <Component />
        </PolicyRestrictionCheck>
      </UserAuthenticationCheck>
    </div>
  </div>
);

const ComponentWithHeaderFooter = (Component, headerProps) => (
  <>
    <a className="skip-link" href="#main_content">
      Skip to Main Content
    </a>
    <ModalProvider>
      <div className="row">
        <DevMode />
        <UserAuthenticationCheck>
          <PolicyRestrictionCheck>
            <div className="mm-shoes">
              <Header {...headerProps} />
              <main id="main_content" className="container main-content">
                <LdBannerContent />
                <Component />
              </main>
              <Footer />
            </div>
          </PolicyRestrictionCheck>
        </UserAuthenticationCheck>
      </div>
    </ModalProvider>
  </>
);

const ComponentWithHeaderFooterUnsecure = (Component) => (
  <>
    <a className="skip-link" href="#main_content">
      Skip to Main Content
    </a>
    <div className="mm-shoes">
      <Header />
      <main id="main_content" className="container main-content">
        <Component />
      </main>
      <Footer />
    </div>
  </>
);

const LaunchDarklyContainer = (props) => {
  const { user } = props;

  const LDComponent = withLDProvider({
    clientSideID: getConfig(configTypes.launchDarkly),
    user,
  })(App);

  return <LDComponent />;
};

const LaunchDarklyWrapper = () => {
  const [launchDarklyWithUser, setLaunchDarklyWithUser] = useState(<> </>);
  const { userDispatch } = useUserState();
  const { agentProfileDispatch } = useAgentProfileState();

  const { oktaUserInfo } = useGetUser({
    userStateTypes,
    userDispatch,
    agentProfileStateTypes,
    agentProfileDispatch,
  });

  useEffect(() => {
    setLaunchDarklyWithUser(<LaunchDarklyContainer />);
  }, []);

  useDeepCompareEffect(() => {
    if (oktaUserInfo?.custom) {
      setLaunchDarklyWithUser(<LaunchDarklyContainer user={oktaUserInfo} />);
    }
  }, [oktaUserInfo]);

  return <> {launchDarklyWithUser}</>;
};

export default LaunchDarklyWrapper;
