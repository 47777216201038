import { useParams } from "react-router-dom";
import { useUserState } from "../../contexts/user";
import { useDevModeContext } from "../../contexts/devMode";
import { useGetFlagsValues } from "../common/featureFlags/hooks";
import { compareArrayOfStrings } from "../../utils/string";

export const useUserAuthCheckProps = () => {
  const { user } = useUserState();
  const { devModeInfo } = useDevModeContext();

  const className = devModeInfo.isVisible ? "col-8" : "col-12";
  return {
    className,
    user,
  };
};

export const usePolicyRestrictionCheckProps = () => {
  const { agreementKey } = useParams();
  const { flagValues } = useGetFlagsValues(["restrictedPolicies"]);

  const restrictedPolicies = flagValues?.restrictedPolicies;
  const haveRestrictedPoliciesLoaded = restrictedPolicies !== false;

  const isRestrictedPolicy = compareArrayOfStrings(
    restrictedPolicies,
    agreementKey?.toString()
  );

  const showChildren = !isRestrictedPolicy && haveRestrictedPoliciesLoaded;

  return {
    showChildren,
    haveRestrictedPoliciesLoaded,
  };
};
