import AgentModal from "../peopleOnPolicy/agentModal";
import {
  concatFirstLastName,
  formatAgencyName,
  formatAgentStatus,
  formatStatusCode,
  formatName,
  formatMoney,
  nameFormattingOptions,
  formatAddress,
  formatLineOfBusiness,
  sortInsuredPeople,
  convertToTitleCase,
  formatAnnuityProductName,
} from "../../../../utils/format";
import { useIsFixedAnnuityContract } from "../../../../hooks/helper";
import {
  getUser,
  userTypes,
  getFirstServicingAgent,
  getUsers,
  getTrimmedPolicyIdFromAgreement,
} from "../../../../utils/findData";
import { formatDate } from "../../../../utils/dates";
import { useMakeRequest } from "../../../../hooks/api";
import { useModalState } from "../../../../contexts/modal";
import {
  compareArrayOfStrings,
  compareStrings,
  stringIsEmptyOrNullish,
} from "../../../../utils/string";
import { isDefined } from "../../../../utils/evaluate";
import ChangeAddress from "../../../changeAddress/index";
import {
  removeOwnerAndInsuredDuplicates,
  isEnvisionPolicy,
  isPolicyInForce,
  isFoundationFlexiblePremium,
  isFoundationSinglePremium,
  isRetireEaseContract,
  isFlexIVFund1FPOrFund4FP,
  isNonEnvisionAnnuity,
} from "../../../../utils/policies";
import { useDisplayAtLeastOneTransactionLink } from "../../../../utils/transactions";
import { showUltimateFaceAmount } from "../detail/hooks";
import { useLDValue } from "../../../common/featureFlags/hooks";

export const getBasicPolicyStyling = (props) => {
  const { showMenu, statusCode } = props;
  const dropdownClassnames = showMenu ? "dropdown show" : "dropdown";
  const dropdownMenuClassnames = showMenu
    ? "dropdown-menu show"
    : "dropdown-menu";
  const primaryBadgeStatusCodes = ["New Business", "In Force"];
  const badgeColor = primaryBadgeStatusCodes.includes(statusCode)
    ? "badge-primary"
    : "badge-secondary";

  return {
    dropdownClassnames,
    dropdownMenuClassnames,
    badgeColor,
  };
};

export const useGetCustomerFullName = (agreementCustomers = []) => {
  const policyOwner = agreementCustomers.reduce((owner, customer) => {
    if (isDefined(owner)) {
      return owner;
    }
    const IS_OWNER =
      compareStrings(customer.roleType, "OWNR") &&
      compareArrayOfStrings(["LIST", "FRAN"], customer.roleSubType);
    if (IS_OWNER) {
      return customer;
    }
    return owner;
  }, null);
  return isDefined(policyOwner)
    ? formatName({
        nameObject: policyOwner,
        format: nameFormattingOptions.firstNameDisplayedFirst,
      })
    : "";
};

export const useOpenChangeAddress = (props) => {
  const { agreementRequest } = props;
  const { openModal } = useModalState();

  const openChangeAddress = () => {
    const modalProps = {
      Component: ChangeAddress,
      componentProps: {
        agreementRequest,
      },
    };
    const AGREEMENT_REQUEST_LOADED = !agreementRequest.isLoading;
    if (AGREEMENT_REQUEST_LOADED) {
      openModal(modalProps);
    }
  };

  return { openChangeAddress };
};

export const usePolicyInfoHeaderProps = (props) => {
  const { agreementRequest } = props;
  const {
    lineOfBusinessCode,
    lineOfBusinessName,
    agreementCustomers,
    status,
    agreementKey,
  } = agreementRequest.data;

  // Functions
  const { openChangeAddress } = useOpenChangeAddress({ agreementRequest });

  // Data
  const lineOfBusiness = formatLineOfBusiness({
    lineOfBusinessCode,
    lineOfBusinessName,
  });
  const statusCode = formatStatusCode(status);

  const { data: policyBasicInfo } = useMakeRequest({
    apiRequest: "fetchBasicPolicyDetails",
    apiParams: {},
    immediateRequest: true,
    defaultReturn: {},
  });

  const isNonEnvisionAnnuityContract = isNonEnvisionAnnuity(agreementRequest);
  const IS_ENVISION_POLICY = isEnvisionPolicy(agreementRequest.data);
  const policyInForce = isPolicyInForce(agreementRequest.data.status);
  const IS_FIXED_ANNUITY = useIsFixedAnnuityContract(agreementRequest.data);

  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);

  const policyOwners = getUsers(agreementCustomers, "OWNR");

  // Styling
  const styling = getBasicPolicyStyling({
    statusCode,
  });

  const { transactionLinkSetFunctions, showTransactionLinkBox } =
    useDisplayAtLeastOneTransactionLink();

  const showEDeliveryBanner = useShowEDeliveryBanner(
    lineOfBusinessCode,
    status
  );

  return {
    functions: {
      openChangeAddress,
    },
    styling,
    data: {
      trimmedContractNbr: trimmedPolicyId,
      policyBasicInfo,
      lineOfBusiness,
      statusCode,
      policyOwners: removeOwnerAndInsuredDuplicates(policyOwners),
      agreementKey,
      IS_ENVISION_POLICY,
      IS_FIXED_ANNUITY,
      policyInForce,
    },
    transactionLinkSetFunctions,
    showTransactionLinkBox:
      showTransactionLinkBox || isNonEnvisionAnnuityContract,
    showEDeliveryBanner,
  };
};

export const useShowEDeliveryBanner = (lineOfBusinessCode, status) => {
  const showEDeliveryBannerFor = useLDValue({
    flagName: ["eDeliveryLobs"],
  });

  return (
    compareArrayOfStrings(showEDeliveryBannerFor, lineOfBusinessCode) &&
    compareStrings(status, "IF")
  );
};

export const useAgentModalProps = (props) => {
  const {
    agentProps: { businessPartnerId },
  } = props;

  const { data: producerData } = useMakeRequest({
    apiRequest: "fetchProducerData",
    apiParams: { producerId: businessPartnerId },
    immediateRequest: true,
    dataSelector: "processProducerData",
  });
  const { address } = producerData;

  const {
    attnLine,
    addrFirstLine,
    addrSecondLine,
    addrThirdLine,
    addrFourthLine,
  } = formatAddress(address);

  return {
    address: {
      attnLine,
      addrFirstLine,
      addrSecondLine,
      addrThirdLine,
      addrFourthLine,
    },
  };
};

export const useServicingAgentModal = () => {
  const { openModal } = useModalState();

  const showServicingAgentModal = (agent) => () => {
    const formattedAgentName = concatFirstLastName(
      agent.firstName,
      agent.lastName,
      agent.fullName
    );
    const {
      businessPartnerId,
      phoneNumber,
      formattedPhoneNumber,
      emailAddress,
      status,
    } = agent;

    const agentProps = {
      formattedAgentName,
      businessPartnerId,
      phoneNumber,
      formattedPhoneNumber,
      emailAddress,
      agencyName: formatAgencyName(agent),
      status: formatAgentStatus(status),
    };
    const modalProps = {
      Component: AgentModal,
      componentProps: { agentProps },
      useHook: useAgentModalProps,
      title: formattedAgentName,
    };
    openModal(modalProps);
  };

  return {
    showServicingAgentModal,
  };
};

export const getDateDisplay = (props) => {
  const { agreementKeyAdmin, effectiveDate, issueDate } = props || {};
  if (agreementKeyAdmin && (effectiveDate || issueDate)) {
    switch (agreementKeyAdmin) {
      case "VNTG1":
        return {
          title: "Policy Date",
          value: stringIsEmptyOrNullish(effectiveDate)
            ? "-"
            : formatDate(effectiveDate, "MM/DD/YYYY", "-"),
        };
      case "PE1":
        return {
          title: "Issue Date",
          value: stringIsEmptyOrNullish(effectiveDate)
            ? "-"
            : formatDate(effectiveDate, "MM/DD/YYYY", "-"),
        };
      case "OPM":
      case "MPR":
      case "PALLM":
      case "HAP":
        return {
          title: "Issue Date",
          value: stringIsEmptyOrNullish(issueDate)
            ? "-"
            : formatDate(issueDate, "MM/DD/YYYY", "-"),
        };
      default:
        return { title: "", value: "" };
    }
  }
  return { title: "", value: "" };
};

export const useAnnuityContractInfoProps = (props) => {
  const { agreementData, producerData, annuityRequest, soaBillingRequest } =
    props;

  const {
    agreementCustomers,
    productTypeName,
    issueDate,
    fundBalanceAmount,
    requestedMaturityDate,
    netPremiumAmount,
    kindCode,
  } = agreementData;

  const isFoundationPremium =
    isFoundationFlexiblePremium(agreementData) ||
    isFoundationSinglePremium(agreementData);

  const modal = useServicingAgentModal();
  const insuredPeople = getUsers(agreementCustomers, "INSD");
  const sortedInsuredPeople = sortInsuredPeople(insuredPeople);

  /* Data processing for Envision policies (kindCode = ENVISN) */
  const servicingAgent = getFirstServicingAgent({
    agents: producerData.producers,
  });

  const formattedAgentName = concatFirstLastName(
    servicingAgent.firstName,
    servicingAgent.lastName,
    servicingAgent.fullName
  );
  const servicingAgentName = servicingAgent.validAgent
    ? `${formattedAgentName} (${servicingAgent.businessPartnerId})`
    : "-";

  const isRetireEaseContractResult = isRetireEaseContract(agreementData);

  let data = {
    productTypeName: productTypeName || "-",
    servicingAgent,
    servicingAgentName,
    issueDate: formatDate(issueDate, "MM/DD/YYYY", "-"),
    insuredPeople: sortedInsuredPeople,
    contractValue: formatMoney(fundBalanceAmount, "-"),
    annuityDate: formatDate(requestedMaturityDate, "MM/DD/YYYY", "-"),
    totalPurchasePaymentAmount: formatMoney(netPremiumAmount, "-"),
    isRetireEaseContractResult,
  };

  /* Data processing for Legacy Annuities Contracts policies (kindCode != ENVISN) */
  if (isNonEnvisionAnnuity({ data: agreementData })) {
    const annuityData = annuityRequest?.data;
    const productNameCombined = formatAnnuityProductName({
      agreementData,
      productName: annuityData?.productName,
      funds: annuityData?.funds,
      surrChargeSchedule: annuityData?.surrChargeSchedule,
      productPrefix: annuityData?.productPrefix,
    });
    const formattedServAgentNameFromAnnuity = formatName({
      nameObject: annuityData?.producerName,
      format: "firstNameDisplayedFirst",
    });
    const annuityServicingAgentName = compareStrings(
      annuityData?.producerRole,
      "Servicing Agent"
    )
      ? `${formattedServAgentNameFromAnnuity} (${servicingAgent?.businessPartnerId})`
      : servicingAgentName;

    const { totalDeposit } = soaBillingRequest?.data || "";

    const totalPurchasePaymentAmount =
      isFoundationPremium || isFlexIVFund1FPOrFund4FP(kindCode)
        ? formatMoney(totalDeposit, "-")
        : formatMoney(annuityData?.totalContributions, "-");

    data = {
      productTypeName: isDefined(productNameCombined)
        ? productNameCombined
        : "-",
      servicingAgent,
      servicingAgentName: annuityServicingAgentName,
      issueDate: formatDate(annuityData?.issueDate, "MM/DD/YYYY", "-"),
      insuredPeople: sortedInsuredPeople,
      annuityDate: formatDate(annuityData?.maturityDate, "MM/DD/YYYY", "-"),
      totalPurchasePaymentAmount,
      contractValue: formatMoney(annuityData?.contractValue, "-"),
      isRetireEaseContractResult,
    };
  }

  return {
    modal,
    data,
  };
};

export const useLifePolicyInfoProps = (props) => {
  const { agreementData, producerData } = props;

  const {
    agreementCustomers,
    productTypeName,
    faceAmount,
    agreementKeyAdmin,
    effectiveDate,
    issueDate,
    ultimateFaceAmount,
  } = agreementData;

  const personInsured = getUser({
    type: userTypes.INSURED,
    people: agreementCustomers,
  });
  const personInsuredFullName = formatName({
    nameObject: personInsured,
    format: nameFormattingOptions.firstNameDisplayedFirst,
  });
  const insuredPeople = getUsers(agreementCustomers, "INSD");
  // const sortedInsuredPeople = sortInsuredPeople(insuredPeople);
  const issueDateDisplay = getDateDisplay({
    agreementKeyAdmin,
    effectiveDate,
    issueDate,
  });

  const servicingAgent = getFirstServicingAgent({
    agents: producerData.producers,
  });

  const formattedAgentName = concatFirstLastName(
    servicingAgent.firstName,
    servicingAgent.lastName,
    servicingAgent.fullName
  );
  const servicingAgentName = servicingAgent.validAgent
    ? `${formattedAgentName} (${servicingAgent.businessPartnerId})`
    : "-";

  const modal = useServicingAgentModal();
  return {
    modal,
    data: {
      personInsuredFullName: stringIsEmptyOrNullish(personInsuredFullName)
        ? "-"
        : personInsuredFullName,
      productTypeName: productTypeName || "-",
      servicingAgent,
      servicingAgentName,
      faceAmount: formatMoney(faceAmount, "-"),
      issueDateTitle: issueDateDisplay?.title,
      issueDate: issueDateDisplay?.value,
      insuredPeople: removeOwnerAndInsuredDuplicates(insuredPeople),
      displayUltimateFaceAmount: showUltimateFaceAmount(agreementData),
      ultimateFaceAmount: formatMoney(ultimateFaceAmount),
    },
  };
};

export const useDisabilityPolicyInfoProps = (props) => {
  const {
    agreementData: {
      agreementCustomers,
      agreementCoverages,
      majorProductName,
      monthlyBenefitMaximumAmount,
      issueDate,
    },
    producerData,
  } = props;
  const personInsured = getUser({
    type: userTypes.INSURED,
    people: agreementCustomers,
  });
  const personInsuredFullName = formatName({
    nameObject: personInsured,
    format: nameFormattingOptions.firstNameDisplayedFirst,
  });
  const insuredPeople = getUsers(agreementCustomers, "INSD");

  const servicingAgent = getFirstServicingAgent({
    agents: producerData.producers,
  });

  const productTypeName = getMarketingNameFromCoverages({ agreementCoverages });
  const formattedProductTypeName = convertToTitleCase(productTypeName);

  const formattedIssueDate = formatDate(issueDate, "MM/DD/YYYY", "-");

  const formattedAgentName = concatFirstLastName(
    servicingAgent.firstName,
    servicingAgent.lastName,
    servicingAgent.fullName
  );
  const servicingAgentName = servicingAgent.validAgent
    ? `${formattedAgentName} (${servicingAgent.businessPartnerId})`
    : "-";
  const modal = useServicingAgentModal();
  return {
    modal,
    data: {
      personInsuredFullName: stringIsEmptyOrNullish(personInsuredFullName)
        ? "-"
        : personInsuredFullName,
      productName: formattedProductTypeName || "-",
      productType: majorProductName || "-",
      servicingAgent,
      servicingAgentName,
      monthlyBenefitMaximumAmount: formatMoney(
        monthlyBenefitMaximumAmount,
        "-"
      ),
      issueDate: formattedIssueDate,
      insuredPeople: removeOwnerAndInsuredDuplicates(insuredPeople),
    },
  };
};

export const getMarketingNameFromCoverages = (props) => {
  const { agreementCoverages } = props;
  const bCategoryCoverage = agreementCoverages.filter(
    (coverage) =>
      compareStrings("BASE", coverage?.type) &&
      compareStrings("B", coverage?.category)
  );
  return bCategoryCoverage?.[0]?.marketingName || "-";
};
