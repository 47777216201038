import { useLDValue } from "../components/common/featureFlags/hooks";
import { getLoggedInUserInfo } from "./findData";
import { useUserState } from "../contexts/user";
import { checkIfTransactionIsAllowed } from "./policies";
import { isDefined } from "./evaluate";
import { supportedTransactions } from "./translate";
import { getConfig, types } from "../config";
import { compareArrayOfStrings } from "./string";

const useAddOrRemoveChatLauncher = (props) => {
  const { agreementData = {}, page = "" } = props;
  const { policyId } = agreementData;
  const { user } = useUserState();
  const { email, firstName, lastName, idNumber } = getLoggedInUserInfo(user);

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.GenesysChat,
    agreementData,
  });

  const isUserAllowedToViewChat = compareArrayOfStrings(
    user.groups,
    "fim-servicenet-chat"
  );

  const isPolicyLoaded = isDefined(policyId);

  if (window.Genesys) {
    if (isTransactionAllowed && isUserAllowedToViewChat && isPolicyLoaded) {
      /* Subscribe to Database.ready event before calling its command. */
      window.Genesys("subscribe", "Database.ready", () => {
        // Set the data in the Database plugin. This data will automatically be included along when sending actual messages.
        window.Genesys(
          "command",
          "Database.set",
          {
            messaging: {
              customAttributes: {
                "WP-Email": email,
                "WP-Producer-ID": idNumber,
                "WP-Last-Name": lastName,
                "WP-First-Name": firstName,
                "WP-Env": getConfig(types.genesysEnv),
                "WP-Page-Info": page,
                "WP-Page-Url": window.location.href,
                "WP-Policy": policyId,
              },
            },
          },
          () => {},
          (error) => {
            console.error(`Error sending chat data ${JSON.stringify(error)}`);
          }
        );
      });

      window.Genesys("subscribe", "Launcher.ready", () => {
        window.Genesys("command", "Launcher.show");
      });
    } else {
      window.Genesys("subscribe", "Launcher.ready", () => {
        window.Genesys("command", "Launcher.hide");
      });
      window.Genesys("subscribe", "Messenger.ready", () => {
        window.Genesys("command", "Messenger.close");
      });
    }
  }
};

export { useAddOrRemoveChatLauncher };
