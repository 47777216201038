import React, { useEffect, useRef, useState } from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { useGetFlagsValues } from "../common/featureFlags/hooks";
import ChangeAddressLink from "./changeAddressLink";
import InitiateLoanLink from "./initiateLoanLink";
import FundTransferLink from "./fundTransferLink";
import DirectLinksLink from "./directLinksLink";
import { useMakeRequest } from "../../hooks/api";
import {
  getBpIdFromUser,
  getPolicyIdFromAgreementKey,
  getPrefixAndSuffixFromAgreementKey,
  getTrimmedPolicyIdFromAgreement,
  getUsers,
} from "../../utils/findData";
import LoadingSection, { loadingTypes, errorTypes } from "../common/loading";
import {
  isEnvisionPolicy,
  isPolicyInForce,
  isPallmPolicy,
} from "../../utils/policies";
import { compareArrayOfStrings, compareStrings } from "../../utils/string";
import { useUserState } from "../../contexts/user";
import EditBillingFrequencyLink from "./editBillingFrequencyLink";
import AnnuityFormsCenterLink from "./annuityFormsCenterLink";
import InForceIllustration from "../common/molecules/inForceIllustration";
import IsTruthy from "../common/logic/isTruthy";
import { isDefined } from "../../utils/evaluate";
import {
  getFundTransferType,
  useDisplayAtLeastOneTransactionLink,
} from "../../utils/transactions";
import { userTypes } from "../../utils/translate";
import InitiateDeathClaimLink from "./initiateDeathClaimLink";
import CashValueQuoteLink from "./cashValueQuoteLink";
import FillableFormLibraryLink from "./fillableFormLibraryLink";

const QuickLinks = (props) => {
  const { row, rowIndex, accessor } = props;
  const statuses = ["Not Reported", "Terminated", "Unknown"];
  const { flagValues } = useGetFlagsValues(["restrictedPolicies"]);

  const {
    transactionLinkSetFunctions: {
      setShowInitiateNewLoanLink,
      setShowChangeAddressLink,
      setShowEditBillingFrequencyLink,
      setShowAnnuityFormsCenterLink,
      setShowInForceIllustrationLink,
      setShowFundTransferLink,
      setShowInitiateDeathClaimLink,
      setShowFillableFormLibraryLink,
      setShowDirectLinksLink,
      setShowCashValueQuoteLink,
    },
    allLinkStatesSetToOff,
  } = useDisplayAtLeastOneTransactionLink();

  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(row.agreementKey);
  const restrictedPolicies = flagValues?.restrictedPolicies;
  const isRestrictedPolicy = compareArrayOfStrings(
    restrictedPolicies,
    row?.agreementKey?.toString()
  );

  const loanRequest = useMakeRequest({
    apiRequest: "fetchLoanData",
    apiParams: { agreementKey: row.agreementKey },
    immediateRequest: false,
    dataSelector: "processLoanInformation",
  });

  const billingRequest = useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey: row.agreementKey, showExtended: false },
    dataSelector: "processBillingInformation",
    immediateRequest: false,
  });
  const serviceHistoryRequest = useMakeRequest({
    apiRequest: "fetchCatsEvents",
    apiParams: { agreementKey: row.agreementKey },
    immediateRequest: false,
    dataSelector: "processServiceHistoryData",
    defaultReturn: [],
  });

  const producersRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey: row.agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey: row.agreementKey },
    immediateRequest: false,
    dataSelector: "processAgreementRequest",
    dependentRequests: [billingRequest, loanRequest, serviceHistoryRequest],
  });

  const agreementData = {
    lineOfBusinessCode: row.lineOfBusinessCode,
    agreementKeyAdmin: row.agreementKeyAdmin,
    productTypeCode: row.productTypeCode,
  };

  const IS_ENVISION_POLICY = isEnvisionPolicy({
    agreementKeyAdmin: row.agreementKeyAdmin,
    lineOfBusinessCode: row.lineOfBusinessCode,
    productTypeCode: row.productTypeCode,
  });
  const IS_NON_ENVISION_ANNUITY =
    compareStrings(row.lineOfBusinessCode, "ANN") && !IS_ENVISION_POLICY;

  const isPallm = isPallmPolicy(agreementData);

  const { user } = useUserState();

  const LINK_IS_ENABLED =
    (IS_NON_ENVISION_ANNUITY ||
      (!statuses.includes(row.status) &&
        isPolicyInForce(row.statusCode) &&
        !isPallm)) &&
    !isRestrictedPolicy;

  const contractNbr = agreementRequest?.data?.policyId;
  const agreementCustomers = agreementRequest?.data?.agreementCustomers;
  const owner = getUsers(agreementCustomers, userTypes.OWNER)[0] || {};
  const { memberGUID } = owner;

  const isAgreementLoaded =
    !agreementRequest.isLoading && isDefined(agreementRequest?.data?.policyId);

  const annuityRequest = useMakeRequest({
    apiRequest: "fetchAnnuityByContractNbr",
    apiParams: { contractNbr },
    dataSelector: "processAnnuityData",
    immediateRequest: false,
    initiateDevModeDispatch: false,
  });

  const fundTransferEligibilityRequest = useMakeRequest({
    apiRequest: "fetchFundTransferEligibility",
    apiParams: {
      memberGUID,
      agreementKey: row.agreementKey,
      fundTransferType: getFundTransferType(agreementData),
    },
    immediateRequest: false,
    dataSelector: "processFundTransferEligibility",
    initiateDevModeDispatch: false,
  });
  const { prefix, suffix } = getPrefixAndSuffixFromAgreementKey(
    row.agreementKey
  );
  const { isAnAgent } = getBpIdFromUser(user);

  const deathClaimEligibilityRequest = useMakeRequest({
    apiRequest: "fetchDeathClaimEligibilityData",
    apiParams: isAnAgent
      ? {
          primaryId: getPolicyIdFromAgreementKey(row.agreementKey),
          prefix,
          suffix,
          adminSystemCode: row.agreementKeyAdmin,
        }
      : {
          primaryId: getPolicyIdFromAgreementKey(row.agreementKey),
          prefix: row.agreementKeyPrefix,
          suffix: row.agreementKeySuffix,
          adminSystemCode: row.agreementKeyAdmin,
        },
    immediateRequest: false,
    dataSelector: "processDeathClaimEligibilityData",
    defaultReturn: [],
  });

  useEffect(() => {
    if (isAgreementLoaded) {
      deathClaimEligibilityRequest.executeRequest();
      if (
        !IS_ENVISION_POLICY &&
        compareStrings(row.lineOfBusinessCode, "ANN")
      ) {
        annuityRequest.executeRequest();
      }

      if (IS_ENVISION_POLICY) {
        producersRequest.executeRequest();
        fundTransferEligibilityRequest.executeRequest();
      }
    }
  }, [isAgreementLoaded, IS_ENVISION_POLICY]);

  const deathClaimEligibilityData = deathClaimEligibilityRequest?.data || {};

  const {
    user: { email },
  } = useUserState();
  const { linkColor } = !LINK_IS_ENABLED
    ? {
        linkColor: "-boulder",
      }
    : {
        linkColor: "",
      };
  const handleQuickLinkButtonClick = () => {
    setShow(!show);
    agreementRequest.executeRequest();
  };
  const buttonLink = (
    <span
      id={`cell-${rowIndex}-${accessor}`}
      ref={target}
      className="justify-content-start"
    >
      <button
        type="button"
        className="btn btn-inline"
        aria-label={`quick-links-button-${row.policyId}`}
        disabled={!LINK_IS_ENABLED}
        onClick={handleQuickLinkButtonClick}
      >
        <span className={`icon-more${linkColor}`} />
      </button>
    </span>
  );
  if (!LINK_IS_ENABLED) {
    return buttonLink;
  }

  const linkEnabledButNoTransactionsAvailable =
    LINK_IS_ENABLED && allLinkStatesSetToOff;

  return (
    <>
      <Overlay
        rootClose
        target={target.current}
        placement="bottom"
        show={show}
        container={target}
        onHide={() => setShow(false)}
      >
        <Popover id={`popover-quick-links-${row.policyId}`}>
          <Popover.Body>
            <LoadingSection
              request={agreementRequest}
              loadingType={loadingTypes.SPINNER}
              errorType={errorTypes.NONE}
            >
              <LoadingSection
                request={billingRequest}
                loadingType={loadingTypes.SPINNER}
                errorType={errorTypes.CHILDREN}
              >
                <LoadingSection
                  request={loanRequest}
                  loadingType={loadingTypes.SPINNER}
                  errorType={errorTypes.CHILDREN}
                >
                  <LoadingSection
                    request={fundTransferEligibilityRequest}
                    loadingType={loadingTypes.SPINNER}
                    errorType={errorTypes.CHILDREN}
                  >
                    <LoadingSection
                      request={annuityRequest}
                      loadingType={loadingTypes.SPINNER}
                      errorType={errorTypes.CHILDREN}
                    >
                      <LoadingSection
                        request={serviceHistoryRequest}
                        loadingType={loadingTypes.SPINNER}
                        errorType={errorTypes.CHILDREN}
                      >
                        <LoadingSection
                          request={deathClaimEligibilityRequest}
                          loadingType={loadingTypes.SPINNER}
                          errorType={errorTypes.CHILDREN}
                        >
                          <ul className="mm-actions-list">
                            <ChangeAddressLink
                              data={agreementRequest.data}
                              policyId={trimmedPolicyId}
                              userEmail={email}
                              isCalledFrom="QuickLink"
                              target="_blank"
                              billingData={billingRequest?.data || {}}
                              setShowChangeAddressLink={
                                setShowChangeAddressLink
                              }
                              isRenderAsListItem
                              annuityData={annuityRequest?.data}
                            />
                            <AnnuityFormsCenterLink
                              email={email}
                              policyId={trimmedPolicyId}
                              agreementData={agreementRequest.data}
                              id="annuity_forms_center_link"
                              className="btn btn-inline"
                              tealiumCategory="QuickLink"
                              renderAsListItem
                              target="_blank"
                              setShowAnnuityFormsCenterLink={
                                setShowAnnuityFormsCenterLink
                              }
                            />
                            <EditBillingFrequencyLink
                              agreementKey={row.agreementKey}
                              lineOfBusinessCode={row.lineOfBusinessCode}
                              agreementData={agreementRequest.data}
                              billingData={billingRequest.data || {}}
                              email={email}
                              policyId={trimmedPolicyId}
                              id="editBillingFrequency-link"
                              className="btn btn-inline"
                              tealiumCategory="QuickLink"
                              renderAsListItem
                              target="_blank"
                              setShowEditBillingFrequencyLink={
                                setShowEditBillingFrequencyLink
                              }
                            />
                            <InitiateLoanLink
                              agreementRequest={agreementRequest}
                              loanData={loanRequest.data}
                              policyId={trimmedPolicyId}
                              agreementKey={row.agreementKey}
                              userEmail={email}
                              setShowInitiateNewLoanLink={
                                setShowInitiateNewLoanLink
                              }
                            />
                            <InitiateDeathClaimLink
                              policyId={trimmedPolicyId}
                              agreementData={agreementRequest?.data}
                              serviceHistoryRequest={serviceHistoryRequest}
                              deathClaimEligibilityData={
                                deathClaimEligibilityData
                              }
                              userEmail={email}
                              isRenderAsListItem
                              isCalledFrom="QuickLink"
                              target="_blank"
                              cssClassName="btn btn-inline"
                              setShowInitiateDeathClaimLink={
                                setShowInitiateDeathClaimLink
                              }
                            />
                            <FillableFormLibraryLink
                              agreementData={agreementRequest.data}
                              setShowFillableFormLibraryLink={
                                setShowFillableFormLibraryLink
                              }
                              policyId={trimmedPolicyId}
                              userEmail={email}
                              tealiumCategory="QuickLink"
                              target="_blank"
                              isRenderAsListItem
                              cssClassName="btn btn-inline"
                            />

                            <DirectLinksLink
                              data={agreementRequest.data}
                              setShowDirectLinksLink={setShowDirectLinksLink}
                              policyId={trimmedPolicyId}
                              userEmail={email}
                              cssClassName="btn btn-inline"
                              tealiumCategory="QuickLink"
                              isRenderAsListItem
                              target="_blank"
                            />
                            <CashValueQuoteLink
                              data={agreementRequest.data}
                              setShowCashValueQuoteLink={
                                setShowCashValueQuoteLink
                              }
                              policyId={trimmedPolicyId}
                              userEmail={email}
                              cssClassName="btn btn-inline"
                              tealiumCategory="QuickLink"
                              isRenderAsListItem
                              target="_blank"
                            />
                            <InForceIllustration
                              policyId={trimmedPolicyId}
                              agreementData={agreementRequest?.data}
                              userEmail={email}
                              isQuickLink
                              setShowInForceIllustrationLink={
                                setShowInForceIllustrationLink
                              }
                            />
                            <FundTransferLink
                              data={agreementRequest.data}
                              setShowFundTransferLink={setShowFundTransferLink}
                              policyId={trimmedPolicyId}
                              userEmail={email}
                              tealiumCategory="QuickLink"
                              isRenderAsListItem
                              target="_blank"
                              fundTransferEligibilityData={
                                fundTransferEligibilityRequest?.data
                              }
                              producersData={producersRequest?.data}
                              user={user}
                            />

                            <IsTruthy
                              value={linkEnabledButNoTransactionsAvailable}
                            >
                              <span id="no-transactions-msg">
                                Transactions are not available for this policy
                                or contract.
                              </span>
                            </IsTruthy>
                          </ul>
                        </LoadingSection>
                      </LoadingSection>
                    </LoadingSection>
                  </LoadingSection>
                </LoadingSection>
              </LoadingSection>
            </LoadingSection>
          </Popover.Body>
        </Popover>
      </Overlay>
      {buttonLink}
    </>
  );
};

export default React.memo(QuickLinks);
