import React from "react";
import RadioForm from "../forms/radioForm";
import IsTruthy from "../logic/isTruthy";
import { compareArrayOfStrings } from "../../../utils/string";
import { isDefined } from "../../../utils/evaluate";
import LoadingSection, { errorTypes, loadingTypes } from "../loading";

const AddressVerification = (props) => {
  const {
    uspsAddress,
    userInputtedAddress,
    verifyLevel,
    confirmClicked,
    handleAddressVerificationConfirmationClick,
    warningInformation,
    verifyAddressRequest,
    handleWarningForAddressVerification,
  } = props;

  if (
    verifyLevel &&
    !compareArrayOfStrings(
      [
        "Verified Match",
        "Verified place",
        "Verified street",
        "Interaction Required",
      ],
      verifyLevel
    ) &&
    !isDefined(warningInformation)
  ) {
    confirmClicked();
  }
  const warningInformationPrompt = warningInformation?.Prompt;
  if (warningInformationPrompt) {
    handleWarningForAddressVerification(warningInformationPrompt);
  }

  return (
    <LoadingSection
      request={verifyAddressRequest}
      loadingType={loadingTypes.SPINNER}
      errorType={errorTypes.CHILDREN}
    >
      <IsTruthy value={verifyLevel} validationFunction={(v) => !!v}>
        <div className="modal-body">
          <p>
            We want to ensure the address is accurate and complete per USPS
            address standards.
          </p>
          <div className="row mm-spacing--minor">
            <div className="col-6">
              <p className="h4">
                <strong>USPS Suggested Address</strong>
              </p>
              <RadioForm formField={uspsAddress} />
            </div>
            <div className="col-6">
              <p className="h4">
                <strong>Address Entered</strong>
              </p>
              <RadioForm formField={userInputtedAddress} />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            id="confirm_address_verification_btn"
            onClick={() =>
              handleAddressVerificationConfirmationClick(uspsAddress)
            }
            type="button"
            className="btn btn-primary"
          >
            Confirm
          </button>
        </div>
      </IsTruthy>
    </LoadingSection>
  );
};

export default AddressVerification;
