import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { types, useFormContext } from "../../../contexts/form";
import { formattingTypes } from "../../../utils/formFormatting";
import { hasError, useGeneralValidation } from "../../../utils/validation";
import countries from "../../../data/countries.json";
import statesAndTerritories from "../../../data/statesAndTerritories.json";
import australianStates from "../../../data/australianStates.json";
import canadianStates from "../../../data/canadianStates.json";
import militaryStates from "../../../data/militaryStates.json";
import citiesAndPostOffice from "../../../data/citiesAndPostOffice.json";
import { isDefined } from "../../../utils/evaluate";
import { addComma, compareArrayOfStrings } from "../../../utils/string";
import "moment-timezone";
import { useUserState } from "../../../contexts/user";
import {
  getTrimmedPolicyIdFromAgreement,
  getLabel,
} from "../../../utils/findData";
import { useMakeRequest } from "../../../hooks/api";
import { fireTealiumEvent } from "../../../utils/tealium";
import { useModalState } from "../../../contexts/modal";
import {
  ADDRESS_BUILDING_INCOMPLETE_ERROR_MESSAGE,
  ADDRESS_INCOMPLETE_ERROR_MESSAGE,
  ADDRESS_INVALID_ERROR_MESSAGE,
  MULTIPLE_ADDRESS_ERROR,
  ADDRESS_INCOMPLETE_ERROR,
} from "../../../constants/ui";
import AddressVerification from "./addressVerification";

export const useEditAddressProps = (props) => {
  const {
    formData: {
      editAddressCountry,
      editAddressAddress1,
      editAddressAddress2,
      editAddressAddress3,
      editAddressIsMilitaryAddress,
      editAddressCity,
      editAddressMilitaryCity,
      editAddressState,
      editAddressMilitaryState,
      editAddressZipCode,
      editAddressMilitaryZipCode,
      userInputtedAddress,
      uspsAddress,
    },
    dispatchForm,
  } = useFormContext();

  const { setError = () => {} } = props;

  const countryDisplay =
    editAddressCountry.display === "UNITED STATES OF AMERICA"
      ? "United States"
      : editAddressCountry.display;

  const countryInfo = countries.find((option) => {
    if (countryDisplay === option.label) {
      return option;
    }
    return null;
  });

  const countryCode = countryInfo ? countryInfo.value : "";

  const showFreeformInputBox = !compareArrayOfStrings(
    ["UNITED STATES OF AMERICA", "USA", "AUS", "CAN"],
    editAddressCountry.value
  );

  const showMilitaryAddressOption = compareArrayOfStrings(
    ["UNITED STATES OF AMERICA", "USA"],
    editAddressCountry.value
  );

  useEffect(() => {
    setError(null);
  }, [editAddressIsMilitaryAddress, setError]);

  useEffect(() => {
    let dropdownOptions;
    switch (editAddressCountry.value) {
      case "UNITED STATES OF AMERICA":
        dropdownOptions = statesAndTerritories;
        break;
      case "USA":
        dropdownOptions = statesAndTerritories;
        break;
      case "AUS":
        dropdownOptions = australianStates;
        break;
      case "CAN":
        dropdownOptions = canadianStates;
        break;
      default:
        dropdownOptions = statesAndTerritories;
    }

    if (
      compareArrayOfStrings(
        ["UNITED STATES OF AMERICA", "USA"],
        editAddressCountry.value
      )
    ) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressZipCode,
          formatting: formattingTypes.ZIPCODE,
          exactLength: 5,
          maxLength: 5,
          required: true,
        },
      });
    } else {
      const { exactLength, ...rest } = editAddressZipCode;
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...rest,
          formatting: formattingTypes.ADDRESS,
          maxLength: 13,
          required: false,
        },
      });
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressIsMilitaryAddress,
          value: false,
        },
      });
    }

    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editAddressState,
        options: dropdownOptions,
      },
    });
  }, [editAddressCountry.value]);

  useDeepCompareEffect(() => {
    const IS_MILTARY_ADDRESS = editAddressIsMilitaryAddress.value;
    let label = "";
    if (IS_MILTARY_ADDRESS) {
      label = `${editAddressAddress1.value}, ${addComma(
        editAddressAddress2.value
      )} ${addComma(editAddressAddress3.value)} ${getLabel(
        citiesAndPostOffice,
        editAddressMilitaryCity.value
      )}, ${getLabel(militaryStates, editAddressMilitaryState.value)}, ${
        editAddressMilitaryZipCode.value
      }, ${countryCode}`;
    } else {
      label = `${editAddressAddress1.value}, ${addComma(
        editAddressAddress2.value
      )} ${addComma(editAddressAddress3.value)} ${editAddressCity.value}, ${
        editAddressState.value
      }, ${editAddressZipCode.value}, ${countryCode}`;
    }
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...userInputtedAddress,
        label,
      },
    });
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...editAddressCountry,
        code: countryCode,
      },
    });
  }, [
    editAddressCountry,
    countryCode,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressMilitaryCity,
    editAddressState,
    editAddressMilitaryState,
    editAddressZipCode,
    editAddressMilitaryZipCode,
    editAddressIsMilitaryAddress,
    userInputtedAddress,
    uspsAddress,
  ]);

  const { hideMandatoryFieldIndicator, isCalledFrom } = props;
  return {
    editAddressCountry,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressIsMilitaryAddress,
    editAddressCity,
    editAddressMilitaryCity,
    editAddressState,
    editAddressMilitaryState,
    editAddressZipCode,
    editAddressMilitaryZipCode,
    showFreeformInputBox,
    showMilitaryAddressOption,
    hideMandatoryFieldIndicator,
    isCalledFrom,
  };
};

export const checkForEditAddressErrors = (props) => {
  const { formData, updateStateVariables } = props;

  const { editAddressCountry, editAddressIsMilitaryAddress } = formData;
  let validationResult = "";

  const { areRequiredFieldsFilled, HAS_ERRORS, ADDRESS3_BUT_NO_ADDRESS2 } =
    checkForRequiredFieldErrors(formData);

  if (areRequiredFieldsFilled && !HAS_ERRORS && !ADDRESS3_BUT_NO_ADDRESS2) {
    if (updateStateVariables) {
      if (
        !editAddressIsMilitaryAddress?.value &&
        compareArrayOfStrings(
          ["UNITED STATES OF AMERICA", "USA"],
          editAddressCountry.value
        )
      ) {
        validationResult = "ValidationCompleted";
      }
    }
  } else if (!areRequiredFieldsFilled) {
    validationResult = "Please fill out all required fields.";
  } else if (ADDRESS3_BUT_NO_ADDRESS2) {
    validationResult = "Please fill address 2 before address 3.";
  } else if (HAS_ERRORS) {
    validationResult = "Please fix input errors.";
  }
  return validationResult;
};

export const useEditAddressFields = () => ({
  editAddressCountry: {
    id: "editAddressCountry",
    name: "editAddressCountry",
    display: "United States",
    label: "Country",
    required: true,
    labelBold: true,
    value: "UNITED STATES OF AMERICA",
    errors: [],
    options: countries,
  },
  editAddressAddress1: {
    id: "editAddressAddress1",
    label: "Address 1",
    labelBold: true,
    required: true,
    placeholder: "",
    name: "editAddressAddress1",
    value: "",
    uncheckedValue: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.ADDRESS,
    useValidation: useGeneralValidation,
    min: 0,
    max: 50,
  },
  editAddressAddress2: {
    id: "editAddressAddress2",
    label: "Address 2",
    labelBold: true,
    placeholder: "",
    name: "editAddressAddress2",
    value: "",
    uncheckedValue: "",
    errors: [],
    postpone: false,
    formatting: formattingTypes.ADDRESS,
    useValidation: useGeneralValidation,
    min: 0,
    max: 50,
  },
  editAddressAddress3: {
    id: "editAddressAddress3",
    label: "Address 3",
    labelBold: true,
    placeholder: "",
    name: "editAddressAddress3",
    value: "",
    uncheckedValue: "",
    errors: [],
    postpone: false,
    formatting: formattingTypes.ADDRESS,
    useValidation: useGeneralValidation,
    min: 0,
    max: 50,
  },
  editAddressIsMilitaryAddress: {
    id: "editAddressIsMilitaryAddress",
    label: "This is a military address",
    name: "editAddressIsMilitaryAddress",
    value: false,
  },
  editAddressCity: {
    id: "editAddressCity",
    label: "City",
    required: true,
    labelBold: true,
    placeholder: "",
    name: "editAddressCity",
    value: "",
    uncheckedValue: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.ADDRESS,
    useValidation: useGeneralValidation,
    min: 1,
    max: 50,
  },
  editAddressMilitaryCity: {
    id: "editAddressMilitaryCity",
    name: "editAddressMilitaryCity",
    display: "City",
    label: "City",
    required: true,
    labelBold: true,
    value: "",
    errors: [],
    options: citiesAndPostOffice,
  },
  editAddressState: {
    id: "editAddressState",
    name: "editAddressState",
    display: "State/Province/Region",
    label: "State/Province/Region",
    required: true,
    labelBold: true,
    value: "",
    errors: [],
    options: statesAndTerritories,
    formatting: formattingTypes.ADDRESS,
    useValidation: useGeneralValidation,
    maxLength: 35,
  },
  editAddressMilitaryState: {
    id: "editAddressMilitaryState",
    name: "editAddressMilitaryState",
    display: "State/Province/Region",
    label: "State/Province/Region",
    required: true,
    labelBold: true,
    value: "",
    errors: [],
    options: militaryStates,
  },
  editAddressZipCode: {
    id: "editAddressZipCode",
    label: "ZIP Code",
    required: true,
    labelBold: true,
    placeholder: "",
    name: "editAddressZipCode",
    value: "",
    uncheckedValue: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.ZIPCODE,
    exactLength: 5,
    maxLength: 5,
    useValidation: useGeneralValidation,
  },
  editAddressMilitaryZipCode: {
    id: "editAddressMilitaryZipCode",
    label: "ZIP Code",
    required: true,
    labelBold: true,
    placeholder: "",
    name: "editAddressMilitaryZipCode",
    value: "",
    uncheckedValue: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.ZIPCODE,
    useValidation: useGeneralValidation,
    exactLength: 5,
    maxLength: 5,
  },
  uspsAddress: {
    id: "uspsAddress",
    label: "",
    name: "uspsAddress",
    groupName: "addressVerificationRadio",
    value: false,
    changedAddress: {},
  },
  userInputtedAddress: {
    id: "userInputtedAddress",
    label: "1234 Ridgefield Rd, Unit 1202 St. Louis, MO 63131 US",
    name: "userInputtedAddress",
    groupName: "addressVerificationRadio",
    value: true,
  },
});

export const useAddressVerificationModal = (props) => {
  const { nextPage, setError } = props;
  const { openModal, closeModal } = useModalState();
  const { agreementKey } = useParams();
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  const {
    user: { email },
  } = useUserState();

  const confirmClicked = () => {
    closeModal();
    nextPage();
  };
  const handleWarningForAddressVerification = (warningInformationPrompt) => {
    const addressVerificationErrorMessage = getAddressVerificationErrorMessage(
      warningInformationPrompt
    );
    setError(addressVerificationErrorMessage);
    closeModal();
  };

  const handleAddressVerificationConfirmationClick = (uspsAddress) => {
    const addressSelected = uspsAddress.value
      ? "USPS Suggested Address Selected"
      : "User Provided Address Selected";
    fireTealiumEvent({
      label: "SVNT",
      action: `Change Address Verification Confirm button clicked and ${addressSelected}`,
      category: "Change Address",
      value: trimmedPolicyId,
      custId: email,
    });
    confirmClicked();
  };

  const addressVerificationModalProps = {
    title: "Address Verification",
    Component: AddressVerification,
    useHook: useGetAddresses,
    componentProps: {
      confirmClicked,
      handleAddressVerificationConfirmationClick,
      handleWarningForAddressVerification,
    },
  };

  const openAddressVerification = () =>
    openModal(addressVerificationModalProps);

  return {
    openAddressVerification,
  };
};

export const useUpdateEditAddressVariables = () => {
  const { formData, dispatchForm } = useFormContext();
  const {
    uspsAddress: { value, changedAddress },
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressState,
    editAddressCountry,
    editAddressZipCode,
  } = formData;

  const {
    Street1,
    Street2,
    Street3 = "",
    City,
    State,
    Zip,
    Country,
  } = changedAddress || {};
  useDeepCompareEffect(() => {
    if (value && changedAddress) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressAddress1,
          value: Street1,
        },
      });
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressAddress2,
          value: Street2,
        },
      });
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressAddress3,
          value: Street3,
        },
      });
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressCity,
          value: City,
        },
      });
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressState,
          value: State,
          display: State,
        },
      });
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressCountry,
          value: Country,
          display: Country,
        },
      });
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressZipCode,
          value: Zip,
        },
      });
    }
  }, [
    value,
    changedAddress,
    editAddressAddress1,
    editAddressAddress2,
    editAddressCity,
    editAddressState,
    editAddressCountry,
    editAddressZipCode,
  ]);
};

export const checkForRequiredFieldErrors = (props) => {
  const {
    editAddressCountry,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressState,
    editAddressZipCode,
    editAddressIsMilitaryAddress,
    editAddressMilitaryCity,
    editAddressMilitaryState,
    editAddressMilitaryZipCode,
  } = props;

  const isMilitaryAddressFilled =
    isDefined(editAddressMilitaryCity?.value) &&
    isDefined(editAddressMilitaryState?.value) &&
    isDefined(editAddressMilitaryZipCode?.value);

  const zipCodeRequired =
    compareArrayOfStrings(
      ["UNITED STATES OF AMERICA", "USA"],
      editAddressCountry.value
    ) && isDefined(editAddressZipCode.value);
  const foreignCountry = !compareArrayOfStrings(
    ["UNITED STATES OF AMERICA", "USA"],
    editAddressCountry.value
  );

  const isRegularAddressFilled =
    isDefined(editAddressCity?.value) &&
    isDefined(editAddressState?.value) &&
    (zipCodeRequired || foreignCountry);

  const areRequiredFieldsFilled =
    isDefined(editAddressCountry.value) &&
    isDefined(editAddressAddress1.value) &&
    (editAddressIsMilitaryAddress?.value
      ? isMilitaryAddressFilled
      : isRegularAddressFilled);

  const ADDRESS3_BUT_NO_ADDRESS2 =
    isDefined(editAddressAddress3.value) &&
    !isDefined(editAddressAddress2.value);

  const HAS_ERRORS_REG_ADD =
    hasError(editAddressCountry) ||
    hasError(editAddressAddress1) ||
    hasError(editAddressAddress2) ||
    hasError(editAddressAddress3) ||
    hasError(editAddressCity) ||
    hasError(editAddressState) ||
    hasError(editAddressZipCode);

  const HAS_ERRORS_MILITARY_ADD =
    hasError(editAddressCountry) ||
    hasError(editAddressAddress1) ||
    hasError(editAddressAddress2) ||
    hasError(editAddressAddress3) ||
    hasError(editAddressMilitaryCity) ||
    hasError(editAddressMilitaryState) ||
    hasError(editAddressMilitaryZipCode);

  const HAS_ERRORS =
    (editAddressIsMilitaryAddress?.value && HAS_ERRORS_MILITARY_ADD) ||
    (!editAddressIsMilitaryAddress?.value && HAS_ERRORS_REG_ADD);

  return {
    HAS_ERRORS_MILITARY_ADD,
    HAS_ERRORS_REG_ADD,
    ADDRESS3_BUT_NO_ADDRESS2,
    areRequiredFieldsFilled,
    HAS_ERRORS,
  };
};

const useGetAddresses = () => {
  const {
    formData: {
      uspsAddress,
      userInputtedAddress,
      editAddressAddress1,
      editAddressAddress2,
      editAddressAddress3,
      editAddressCity,
      editAddressState,
      editAddressZipCode,
    },
    dispatchForm,
  } = useFormContext();

  const addressToBeVerified = {
    line1: editAddressAddress1.value,
    line2: editAddressAddress2.value,
    line3: editAddressAddress3.value,
    city: editAddressCity.value,
    countryCode: "US",
    stateOrProvinceCode: editAddressState.value,
    postalCode: editAddressZipCode.value,
  };

  const verifyAddressRequest = useMakeRequest({
    apiRequest: "fetchUSPSAddressVerification",
    apiParams: {
      address: [addressToBeVerified],
    },
    immediateRequest: true,
  });

  const { data } = verifyAddressRequest;
  const { Address, WarningInformation } = data || {};
  const { Street1, Street2, Street3, City, State, Zip, Country } =
    Address || {};
  const street2 = Street2 ? ` ${Street2}` : "";
  const street3 = Street3 ? `, ${Street3}` : "";
  const concatedAddress = Address
    ? `${Street1},${street2}${street3} ${City}, ${State}, ${Zip}, ${Country}`
    : "";

  useDeepCompareEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...uspsAddress,
        label: concatedAddress,
        changedAddress: Address,
      },
    });
  }, [concatedAddress, uspsAddress, Address, dispatchForm]);

  return {
    uspsAddress,
    userInputtedAddress,
    verifyLevel: data?.VerificationFlags?.VerifyLevel,
    warningInformation: WarningInformation,
    verifyAddressRequest,
  };
};

export const useCountryDropdownProps = (props) => {
  const { formField } = props;
  const { dispatchForm, formData } = useFormContext();
  const selectedOption = (event) => {
    const clickedOption = formField.options.reduce(
      (foundOption, currentOption) => {
        const ALREADY_FOUND_MATCH = isDefined(foundOption);
        const FOUND_A_MATCH = currentOption.value === event.target.id;

        if (ALREADY_FOUND_MATCH) {
          return foundOption;
        }
        if (FOUND_A_MATCH) {
          return currentOption;
        }

        return foundOption;
      },
      undefined
    );
    const { editAddressAddress1 } = formData;
    if (
      !compareArrayOfStrings(
        ["United States of America", "United States", "USA"],
        clickedOption.label
      )
    ) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressAddress1,
          min: 5,
        },
      });
    } else {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...editAddressAddress1,
          min: 0,
        },
      });
    }
    dispatchForm({
      type: types.UPDATE_DROPDOWN,
      formField,
      value: clickedOption.value,
      display: clickedOption.label,
    });
  };

  return {
    functions: { selectedOption },
  };
};

export const getAddressVerificationErrorMessage = (warningInformation) => {
  let error = null;
  if (isDefined(warningInformation)) {
    switch (warningInformation) {
      case ADDRESS_INCOMPLETE_ERROR:
        error = ADDRESS_INCOMPLETE_ERROR_MESSAGE;
        break;
      case MULTIPLE_ADDRESS_ERROR:
        error = ADDRESS_INCOMPLETE_ERROR_MESSAGE;
        break;
      case ADDRESS_BUILDING_INCOMPLETE_ERROR_MESSAGE:
        error = ADDRESS_BUILDING_INCOMPLETE_ERROR_MESSAGE;
        break;
      default:
        error = ADDRESS_INVALID_ERROR_MESSAGE;
    }
  }
  return error;
};
