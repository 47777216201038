/* eslint-disable no-underscore-dangle */
import React from "react";
import { ListBasic } from "react-blueprint-library";
import { isPolicyInForce } from "../../../../../utils/policies";
import IsTruthy from "../../../../common/logic/isTruthy";
import { isDefined } from "../../../../../utils/evaluate";
import LoadingSection, {
  errorTypes,
  loadingTypes,
} from "../../../../common/loading";
import { usePolicyInfoHeaderProps } from "../hooks";
import InitiateLoanLink from "../../../../home/initiateLoanLink";
import DisabilityPolicyInfo from "./policyInfo";
import EditBillingFrequencyLink from "../../../../home/editBillingFrequencyLink";
import ChangeAddressLink from "../../../../home/changeAddressLink";
import UserRegisteredSignifier from "../../../../common/userRegisteredSignifier/userRegisteredSignifier";
import InitiateDeathClaimLink from "../../../../home/initiateDeathClaimLink";
import DirectLinksLink from "../../../../home/directLinksLink";
import { DigitalRegistrationLink } from "../../../../home/digitalRegistrationLink";
import FillableFormLibraryLink from "../../../../home/fillableFormLibraryLink";
import { E_DELIVERY_BANNER_TEXT } from "../../../../../constants/ui";

const DisabilityPolicyInfoHeader = (props) => {
  const {
    policyId,
    agreementRequest,
    loanRequest,
    billingRequest,
    serviceHistoryRequest,
    deathClaimEligibilityRequest,
    openDrawer,
    unsupportedPolicy,
    producerData,
    email,
    lineOfBusinessCode,
  } = props;
  const {
    styling: { badgeColor },
    data: { policyBasicInfo, lineOfBusiness, statusCode, policyOwners },
    transactionLinkSetFunctions: {
      setShowInitiateNewLoanLink,
      setShowChangeAddressLink,
      setShowEditBillingFrequencyLink,
      setShowInitiateDeathClaimLink,
      setShowDirectLinksLink,
      setShowFillableFormLibraryLink,
    },
    showTransactionLinkBox,
    showEDeliveryBanner,
  } = usePolicyInfoHeaderProps(props);

  const policyInForce = isPolicyInForce(agreementRequest.data.status);

  const ownersList = policyOwners.map((customer, index) => {
    const { fullName } = customer;
    return (
      <div key={`${fullName}`}>
        <IsTruthy value={fullName} validationFunction={isDefined}>
          <h2 id={`policy_owner_${index}_name_and_signifier`}>
            <span
              id={`policy_owner_${index}_full_name`}
              className="mm-illustration-buffer"
            >
              {fullName}
            </span>{" "}
            <UserRegisteredSignifier
              customer={customer}
              isCalledFrom="PDP"
              indexNumber={index}
            />
          </h2>
          <div className="mm-sub-copy hide-print">
            <ListBasic variant="separated">
              <ListBasic.ListBasicItem>
                {" "}
                <button
                  id={`view_customer_${index}_details_btn`}
                  type="button"
                  className="btn btn-inline"
                  onClick={openDrawer(customer)}
                >
                  View Details
                </button>
              </ListBasic.ListBasicItem>
              <DigitalRegistrationLink
                agreementData={agreementRequest?.data}
                customer={customer}
                isCalledFrom="PDP"
                policyId={policyId}
                lineOfBusinessCode={lineOfBusinessCode}
              />
            </ListBasic>
          </div>
        </IsTruthy>
      </div>
    );
  });

  const billingData = billingRequest?.data || {};
  return (
    <LoadingSection
      request={agreementRequest}
      loadingType={loadingTypes.PARAGRAPH}
      errorType={errorTypes.NONE}
      unsupportedPolicy={unsupportedPolicy}
    >
      <IsTruthy value={showEDeliveryBanner}>
        <div
          id="eDelivey_Banner_DI_pdp"
          className="alert alert-info"
          role="alert"
        >
          {E_DELIVERY_BANNER_TEXT}
        </div>
      </IsTruthy>
      <h1 id="policy_detail_title_value">
        {policyId} ({lineOfBusiness}){" "}
        <span
          id="policy_detail_status_value"
          className={`badge badge-pill ${badgeColor}`}
        >
          {statusCode}
        </span>
      </h1>
      <div className="row">
        <div className="col-lg-8">
          <IsTruthy
            value={policyOwners}
            validationFunction={(list) => list.length}
          >
            <p id="policy_owner_lbl" className="eyebrow">
              POLICY OWNER(S)
            </p>
          </IsTruthy>
          {ownersList}
          <DisabilityPolicyInfo
            policyId={policyId}
            policyBasicInfo={policyBasicInfo}
            agreementData={agreementRequest.data}
            producerData={producerData}
          />
        </div>

        <IsTruthy value={policyInForce && showTransactionLinkBox}>
          <div id="quick_actions_desktop" className="col-lg-4">
            <LoadingSection
              request={loanRequest}
              loadingType={loadingTypes.PARAGRAPH}
              errorType={errorTypes.CHILDREN}
            >
              <LoadingSection
                request={billingRequest}
                loadingType={loadingTypes.PARAGRAPH}
                errorType={errorTypes.CHILDREN}
              >
                <div className="card">
                  <div className="card-body">
                    <ul className="mm-list-group--quick-actions">
                      <ChangeAddressLink
                        data={agreementRequest.data}
                        policyId={policyId}
                        userEmail={email}
                        cssClassName="btn btn-inline mm-spacing--none"
                        isCalledFrom="PDP"
                        billingData={billingData}
                        isRenderAsListItem
                        setShowChangeAddressLink={setShowChangeAddressLink}
                      />
                      <EditBillingFrequencyLink
                        agreementKey={agreementRequest.data.agreementKey}
                        lineOfBusinessCode={
                          agreementRequest.data.lineOfBusinessCode
                        }
                        agreementData={agreementRequest.data}
                        billingData={billingData}
                        email={email}
                        policyId={policyId}
                        id="edit_billing_frequency_link"
                        className="btn btn-inline mm-spacing--none"
                        tealiumCategory="PDP"
                        renderAsListItem
                        setShowEditBillingFrequencyLink={
                          setShowEditBillingFrequencyLink
                        }
                      />
                      <InitiateLoanLink
                        agreementRequest={agreementRequest}
                        loanData={loanRequest.data}
                        email={email}
                        policyId={policyId}
                        agreementKey={agreementRequest.data.agreementKey}
                        isPDP
                        setShowInitiateNewLoanLink={setShowInitiateNewLoanLink}
                      />
                      <InitiateDeathClaimLink
                        policyId={policyId}
                        userEmail={email}
                        cssClassName="btn btn-inline mm-spacing--none"
                        isCalledFrom="PDP"
                        agreementData={agreementRequest.data}
                        serviceHistoryRequest={serviceHistoryRequest}
                        deathClaimEligibilityData={
                          deathClaimEligibilityRequest?.data
                        }
                        isRenderAsListItem
                        setShowInitiateDeathClaimLink={
                          setShowInitiateDeathClaimLink
                        }
                      />
                      <FillableFormLibraryLink
                        agreementData={agreementRequest.data}
                        setShowFillableFormLibraryLink={
                          setShowFillableFormLibraryLink
                        }
                        policyId={policyId}
                        userEmail={email}
                        tealiumCategory="PDP"
                        isRenderAsListItem
                        cssClassName="btn btn-inline mm-spacing--none"
                      />
                      <DirectLinksLink
                        policyId={policyId}
                        userEmail={email}
                        cssClassName="btn btn-inline mm-spacing--none"
                        tealiumCategory="PDP"
                        data={agreementRequest.data}
                        isRenderAsListItem
                        setShowDirectLinksLink={setShowDirectLinksLink}
                      />
                    </ul>
                  </div>
                </div>
              </LoadingSection>
            </LoadingSection>
          </div>
        </IsTruthy>
      </div>
    </LoadingSection>
  );
};

export default React.memo(DisabilityPolicyInfoHeader);
