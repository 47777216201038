import { useEffect, useState } from "react";
import { useMakeRequest } from "../../../../hooks/api";
import { isDefined, isEmptyObject } from "../../../../utils/evaluate";
import { useUserState } from "../../../../contexts/user";
import { useLDValue } from "../../../common/featureFlags/hooks";
import { verifyLandingPageNotifications } from "../../restrictions";
import { getDaysDifference } from "../../../../utils/dates";
import {
  NOTIFICATIONS_ERROR_TEXT,
  STANDARD_ERROR_TEXT,
} from "../../../../constants/ui";
import {
  fireTealiumAndFollowLink,
  fireTealiumEvent,
  tealiumEventSource,
} from "../../../../utils/tealium";
import { getBpIdFromUser } from "../../../../utils/findData";

export const useHeaderNotificationDropdownProps = (props) => {
  const { tealiumEventSourcePage } = props;

  const [dateFilter, setDateFilter] = useState("");
  const [isInitialClick, setIsInitialClick] = useState(true);
  const [reloadAttempts, setReloadAttempts] = useState(0);
  const maxReloadAttempts = 3;
  const maxReloadAttemptsReached = reloadAttempts >= maxReloadAttempts;

  const numberOfDaysThreshold = useLDValue({
    flagName: ["maxDaysNewNotifications"],
  });

  const agentUnseenNotificationsCountRequest = useMakeRequest({
    apiRequest: "fetchAgentUnseenNotificationsCount",
    apiParams: { daysThreshold: numberOfDaysThreshold },
    immediateRequest: false,
    dataSelector: "processAgentUnseenNotificationsCountData",
    initiateDevModeDispatch: true,
  });

  const agentNotificationsRequest = useMakeRequest({
    apiRequest: "fetchAgentNotifications",
    apiParams: {
      daysThreshold: numberOfDaysThreshold,
      dateFilter,
      extended: false,
    },
    immediateRequest: false,
    dataSelector: "processAgentNotificationsData",
    initiateDevModeDispatch: false,
  });

  const updateNotificationsLastViewedRequest = useMakeRequest({
    apiRequest: "updateNotificationsLastViewed",
    apiParams: {
      viewedAt: dateFilter,
    },
    immediateRequest: false,
    initiateDevModeDispatch: false,
  });

  const { user } = useUserState();

  const landingPageNotificationsEnabled = useLDValue({
    flagName: ["landingPageNotifications"],
  });

  const { isEligible } = verifyLandingPageNotifications({
    user,
    landingPageNotificationsEnabled,
  });

  const { notifications = [] } = agentNotificationsRequest.data;

  useEffect(() => {
    if (isEligible) {
      agentUnseenNotificationsCountRequest.executeRequest();
    }
  }, [isEligible]);

  const { email } = user;
  const { idNumber: agentIDNum } = getBpIdFromUser(user);

  const onNotificationsDropDownToggle = (isExpand) => {
    if (isInitialClick) {
      agentNotificationsRequest.executeRequest();
      setIsInitialClick(false);
    }

    if (isExpand) {
      const tealiumAction = getTealiumActionByPage(
        tealiumEventSourcePage,
        notificationEventSourceElement.DROPDOWN_TOGGLE_BUTTON
      );

      fireTealiumEvent({
        label: "SVNT",
        action: tealiumAction,
        category: "Landing Page Notifications",
        value: agentIDNum,
        custId: email,
      });
    }
  };

  const onViewAllNotificationsClick = () => {
    const tealiumAction = getTealiumActionByPage(
      tealiumEventSourcePage,
      notificationEventSourceElement.VIEW_ALL_BUTTON
    );

    fireTealiumAndFollowLink({
      url: "/agentServiceTracking",
      action: tealiumAction,
      category: "Landing Page Notifications",
      value: agentIDNum,
      custId: email,
    });
  };

  const onNotificationItemNameClick = (notification) => {
    const tealiumAction = getTealiumActionByPage(
      tealiumEventSourcePage,
      notificationEventSourceElement.NOTIFICATION_ITEM_NAME
    );

    fireTealiumAndFollowLink({
      url: `/policyDetails/${encodeURIComponent(
        notification.agreementKey
      )}/viewServiceHistory`,
      action: tealiumAction,
      category: "Landing Page Notifications",
      value: agentIDNum,
      custId: email,
    });
  };

  const hasError = isDefined(agentNotificationsRequest.error);

  let hasNewNotifications = false;
  let notificationsLastViewedDate = "";

  if (!isEmptyObject(agentUnseenNotificationsCountRequest?.data)) {
    hasNewNotifications =
      agentUnseenNotificationsCountRequest?.data?.unseenNotificationsCount > 0;
    notificationsLastViewedDate =
      agentUnseenNotificationsCountRequest?.data?.unseenNotificationsAsOfDate;
  }

  const notificationsSorted = formatNotificationsForDropdown(notifications);

  useEffect(() => {
    setDateFilter(notificationsLastViewedDate);
  }, [notificationsLastViewedDate]);

  const customErrorMessage = maxReloadAttemptsReached
    ? STANDARD_ERROR_TEXT
    : NOTIFICATIONS_ERROR_TEXT;

  const notificationsDataHasLoaded = isDefined(
    agentNotificationsRequest?.data?.notifications
  );

  useEffect(() => {
    if (isDefined(dateFilter)) {
      updateNotificationsLastViewedRequest.executeRequest();
    }
  }, [notificationsDataHasLoaded]);

  const isUnseenCountDataLoaded = isDefined(
    agentUnseenNotificationsCountRequest?.data?.unseenNotificationsAsOfDate
  );

  return {
    agentNotificationsRequest,
    hasNewNotifications: notificationsDataHasLoaded
      ? false
      : hasNewNotifications,
    notificationsLastViewedDate,
    hasError,
    isEligible,
    notifications: notificationsSorted,
    onNotificationsDropDownToggle,
    onViewAllNotificationsClick,
    onNotificationItemNameClick,
    reloadAttempts,
    setReloadAttempts,
    maxReloadAttemptsReached,
    customErrorMessage,
    isUnseenCountDataLoaded,
  };
};

export const formatNotificationsForDropdown = (notifications) => {
  const notificationsWithAge = notifications.map((notification) => ({
    ...notification,
    daysOld: getDaysDifference(new Date(), new Date(notification.createdDate)),
  }));
  return notificationsWithAge
    .sort((a, b) => {
      if (a.isNew === b.isNew) return 0;
      if (a.isNew) return -1;
      return 1;
    })
    .sort((a, b) => {
      if (a.isPriority === b.isPriority) return 0;
      if (a.isPriority) return -1;
      return 1;
    })
    .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
};

export const getTealiumActionByPage = (
  tealiumEventSourcePage,
  eventSourceElement
) => {
  let tealiumAction = "";
  if (eventSourceElement === notificationEventSourceElement.VIEW_ALL_BUTTON) {
    switch (tealiumEventSourcePage) {
      case tealiumEventSource.LANDING_PAGE:
        tealiumAction = `Service Tracking Notifications Landing Page View All button clicked`;
        break;
      case tealiumEventSource.POLICY_DETAILS_PAGE:
        tealiumAction =
          "Service Tracking Notifications Policy Detail View All button clicked";
        break;
      default:
        tealiumAction = "Unknown";
    }
  } else if (
    eventSourceElement === notificationEventSourceElement.DROPDOWN_TOGGLE_BUTTON
  ) {
    switch (tealiumEventSourcePage) {
      case tealiumEventSource.LANDING_PAGE:
        tealiumAction = `Service Tracking Notifications Landing Page Dropdown button clicked`;
        break;
      case tealiumEventSource.POLICY_DETAILS_PAGE:
        tealiumAction =
          "Service Tracking Notifications Policy Detail Dropdown button clicked";
        break;
      default:
        tealiumAction = "Unknown";
    }
  } else if (
    eventSourceElement === notificationEventSourceElement.NOTIFICATION_ITEM_NAME
  ) {
    switch (tealiumEventSourcePage) {
      case tealiumEventSource.LANDING_PAGE:
        tealiumAction = `Service Tracking Notifications Landing Page Name button clicked`;
        break;
      case tealiumEventSource.POLICY_DETAILS_PAGE:
        tealiumAction =
          "Service Tracking Notifications Policy Detail Name button clicked";
        break;
      default:
        tealiumAction = "Unknown";
    }
  } else {
    tealiumAction = "Unknown";
  }
  return tealiumAction;
};

export const notificationEventSourceElement = {
  DROPDOWN_TOGGLE_BUTTON: "Dropdown Toggle Button",
  VIEW_ALL_BUTTON: "View All Button",
  NOTIFICATION_ITEM_NAME: "Notification Item Name",
};
