import React from "react";
import { compareStrings } from "../../../utils/string";
import IsTruthy from "../../common/logic/isTruthy";

const NotificationDataCell = (props) => {
  const {
    row,
    rowIndex,
    column: { accessor },
  } = props;

  if (compareStrings(accessor, "notificationPolicyNumber")) {
    return (
      <>
        <IsTruthy value={row.newNotification}>
          <span
            className="badge badge-indicator--flat"
            data-testid="new-notification-indicator"
            id={`new-notification-indicator_${rowIndex}`}
          />{" "}
        </IsTruthy>
        <span id={`${accessor}_${rowIndex}_value`}>{row[accessor]}</span>
      </>
    );
  }
  return <span id={`${accessor}_${rowIndex}_value`}>{row[accessor]}</span>;
};

export default NotificationDataCell;
