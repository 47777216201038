import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { AGREEMENT_NOT_FOUND } from "../../constants/ui";
import { types, useFormContext } from "../../contexts/form";
import { useUserState } from "../../contexts/user";
import { useMakeRequest } from "../../hooks/api";
import { isDefined } from "../../utils/evaluate";
import {
  getBillingModeData,
  getModalPremiumsForPolicy,
  getServiceHistoryRequestsbyEventNameAndStatus,
  getRequestorInfo,
  getUsers,
  isHomeOfficeUser,
  getBpIdFromUser,
  getFirstServicingAgent,
  getTrimmedPolicyIdFromAgreement,
  getAdminFromAgreementKey,
} from "../../utils/findData";
import { getLastXChars } from "../../utils/format";
import {
  isDisabilityPolicy,
  removeOwnerAndInsuredDuplicates,
} from "../../utils/policies";
import { compareArrayOfStrings, compareStrings } from "../../utils/string";
import ModalPopup from "../common/popup/modalPopup";
import { useModalState } from "../../contexts/modal";
import {
  formatDate,
  getCurrentDate,
  getCurrentDateTimeWithTimezone,
} from "../../utils/dates";
import { createCatsEvents } from "../../api";
import { fireTealiumEvent } from "../../utils/tealium";
import {
  isAgreementEligibleForFrequencyChange,
  verifyChangeBillingFrequency,
} from "./restrictions";
import { checkForRequestorContactError } from "../common/requestorContact/hooks";
import { useRequestorContactEmail } from "../../hooks/helper";
import { useChangeBillingFrequencyFormFields } from "./pages/changeBillingFrequencyFormFields";
import { useLDValue } from "../common/featureFlags/hooks";
import { typesOfAgents } from "../../data/typesOfAgents";
import { datePickerInputValidation } from "../../utils/validation";
import { useAddOrRemoveChatLauncher } from "../../utils/genesysChatClient";

export const useChangeBillingFrequencyProps = (agreementKey) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [transactionId, setTransactionId] = useState("");
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [error, setError] = useState(null);
  const nextPage = () => setPageNumber(pageNumber + 1);
  const lastPage = () => setPageNumber(pageNumber - 1);
  const previousPage = () => {
    lastPage();
    setError(null);
  };

  const { user } = useUserState();

  const shouldDisableSubmit = isHomeOfficeUser(user);
  const { agentType } = getBpIdFromUser(user);
  const isMmsdOnlyUser = compareStrings(agentType, typesOfAgents.MMSD);

  const validRequest = (dependentAgreementData) => {
    const { isUnauthorized } = dependentAgreementData;
    const { type, description } = dependentAgreementData?.messages || {};
    return (
      !isUnauthorized &&
      compareStrings(type, "SUCCESS") &&
      !compareStrings(description, AGREEMENT_NOT_FOUND) &&
      isAgreementEligibleForFrequencyChange(dependentAgreementData)
    );
  };

  const billingRequest = useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey, showExtended: false },
    dataSelector: "processBillingInformation",
    immediateRequest: false,
    updateRequestCallback: (dependentAgreementData) => {
      const { issueDate, jurisdictionState } = dependentAgreementData;
      const dataForSelectors = isDefined(issueDate)
        ? { jurisdictionState }
        : { issueDate, jurisdictionState };
      return { paramsForSelectors: dataForSelectors };
    },
    canExecuteCallBack: validRequest,
  });

  const serviceHistoryRequest = useMakeRequest({
    apiRequest: "fetchCatsEvents",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processServiceHistoryData",
    defaultReturn: [],
    canExecuteCallBack: validRequest,
  });

  const producerRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
    canExecuteCallBack: validRequest,
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    dependentRequests: [billingRequest, serviceHistoryRequest, producerRequest],
  });

  const agreementData = agreementRequest?.data || {};
  const { agreementCustomers, policyId, lineOfBusinessCode, isPolicyNotFound } =
    agreementData;
  const isPolicyLoaded = isDefined(policyId) && isDefined(lineOfBusinessCode);

  const owners = getUsers(agreementCustomers, "OWNR") || [];
  const insured = getUsers(agreementCustomers, "INSD") || [];
  const productTypeName = agreementData?.productTypeName || "-";

  const billingData = billingRequest?.data || {};
  const serviceHistoryData = serviceHistoryRequest?.data || {};

  const hasBillingFrequencyChangeTransactionPending = isDefined(
    getServiceHistoryRequestsbyEventNameAndStatus(
      serviceHistoryData,
      "Pending",
      ["ServiceNet Frequency Change", "ServiceNet PAC Removal"]
    )
  );
  const modalPremiums = getModalPremiumsForPolicy({
    agreementData,
    billingData,
  });
  const { billingModeData } = getBillingModeData(modalPremiums);

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });
  const { isEligible, isTransactionAllowed } = verifyChangeBillingFrequency({
    agreementData,
    billingData,
    billingModeData,
    transactionSupportedPolicies,
  });

  const fields = useChangeBillingFrequencyFormFields();

  const onSubmit = (formData) => {
    const headers = {
      Authorization: `Bearer ${user.accessToken}`,
    };

    const requestBody = makeChangeBillingFrequencyRequestBody({
      formData,
      agreementKey,
      agreementRequest,
      producerRequest,
      user,
      billingData,
    });

    createCatsEvents({ headers, apiParams: requestBody })
      .then((result) => {
        setTransactionId(result?.data?.transGUID);
        if (result?.status !== 200) {
          throw new Error("non 200 response");
        } else {
          if (result?.data?.messages?.[0]?.msgType !== "SUCCESS") {
            throw new Error("one or more of requests failed");
          }
          fireTealiumEvent({
            label: "SVNT",
            action: `${lineOfBusinessCode}: Frequency Change/PAC Removal submitted`,
            category: "Frequency Change/PAC Removal",
            value: policyId,
            custId: user?.email,
          });
          setPageNumber(3);
        }
      })
      .catch((e) => {
        fireTealiumEvent({
          label: "SVNT",
          action: `${lineOfBusinessCode} Frequency Change/PAC Removal Failed`,
          category: "Frequency Change/PAC Removal",
          value: policyId,
          custId: user?.email,
        });
        setErrorText(
          compareStrings("Network Error", e.message)
            ? "The page you are looking for is temporarily unavailable."
            : "There was an error processing your request; please try again later."
        );
        setShowErrorPage(true);
      });
  };

  const { isUnauthorized } = agreementData;

  useAddOrRemoveChatLauncher({
    agreementData,
    page: "Change Billing Frequency transaction",
  });

  return {
    owners: removeOwnerAndInsuredDuplicates(owners),
    insured: removeOwnerAndInsuredDuplicates(insured),
    productTypeName,
    policyId,
    lineOfBusinessCode,
    agreementRequest,
    billingRequest,
    producerRequest,
    serviceHistoryRequest,
    agreementData,
    billingData,
    billingModeData,
    isMmsdOnlyUser,
    isEligible,
    isTransactionAllowed,
    isPolicyLoaded,
    fields,
    pageNumber,
    setPageNumber,
    nextPage,
    previousPage,
    error,
    setError,
    onSubmit,
    shouldDisableSubmit,
    hasBillingFrequencyChangeTransactionPending,
    showErrorPage,
    transactionId,
    errorText,
    isUnauthorized,
    email: user?.email,
    isPolicyNotFound,
  };
};

export const useChangeBillingFrequencyHeaderProps = (props) => {
  const { hasBillingFrequencyChangeTransactionPending, lineOfBusinessCode } =
    props;
  const { openPendingTransactionModal } = usePendingTransactionModalProps({
    lineOfBusinessCode,
  });
  useEffect(() => {
    if (hasBillingFrequencyChangeTransactionPending) {
      openPendingTransactionModal();
    }
  }, []);
};

export const usePendingTransactionModalProps = (props) => {
  const { agreementKey } = useParams();
  const { lineOfBusinessCode } = props;

  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);

  const { user } = useUserState();

  const { openModal, closeModal } = useModalState();

  const cancelPendingTransaction = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: `${lineOfBusinessCode}: Frequency Change/PAC Removal - cancel pending transaction`,
      category: "Frequency Change/PAC Removal",
      value: trimmedPolicyId,
      custId: user?.email,
    });
  };

  const pendingTransacationModalProps = {
    title: "Transaction Pending",
    Component: ModalPopup,
    componentProps: {
      closeModal,
      agreementKey,
      modalBodyMessage:
        "A current transaction is pending, would you like to continue? To view current transaction,",
      hasUrlInMessage: true,
      UrlLink: `/policyDetails/${encodeURIComponent(
        agreementKey
      )}/viewServiceHistory`,
      UrlName: "view Service History",
      cancelPendingTransaction,
    },
  };

  const openPendingTransactionModal = () =>
    openModal(pendingTransacationModalProps);

  return {
    openPendingTransactionModal,
  };
};

export const usePacToPacModalProps = (props) => {
  const { openModal, closeModal } = useModalState();
  const { onClickPACForm } = useCanGoToPACForm(props);
  const handleYesButtonClick = () => {
    closeModal();
    onClickPACForm();
  };
  const handleNoButtonClick = () => {
    closeModal();
  };
  const pactopacModalProps = {
    title: "Before continuing...",
    Component: ModalPopup,
    componentProps: {
      closeModal: handleYesButtonClick,
      modalBodyMessage:
        "This policy is currently on PAC. If this is being changed to another PAC frequency option, does the bank information need to be updated (ex. routing number)?",
      hasUrlInMessage: false,
      cancelButtonMessage: "No",
      continueButtonMessage: "Yes",
      customCancelButton: true,
      cancelButtonFunction: handleNoButtonClick,
    },
  };

  const openPacToPacModal = () => openModal(pactopacModalProps);

  return {
    openPacToPacModal,
  };
};

export const useBillingFrequencyProps = (props) => {
  const { formData, dispatchForm } = useFormContext();
  const { billingModeData } = props;
  const { agreementKey } = useParams();
  const agreementKeyAdmin = getAdminFromAgreementKey(agreementKey);

  const { monthlyBilling, quarterlyBilling, semiAnnualBilling, annualBilling } =
    formData;

  useDeepCompareEffect(() => {
    if (billingModeData.monthlyBillingData.isCurrentBillingMode) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...monthlyBilling,
          isCurrentBillingMode: true,
        },
      });
    }
    if (billingModeData.quarterlyBillingData.isCurrentBillingMode) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...quarterlyBilling,
          isCurrentBillingMode: true,
        },
      });
    }
    if (billingModeData.semiAnnualBillingData.isCurrentBillingMode) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...semiAnnualBilling,
          isCurrentBillingMode: true,
        },
      });
    }
    if (billingModeData.annualBillingData.isCurrentBillingMode) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...annualBilling,
          isCurrentBillingMode: true,
        },
      });
    }
  }, [
    annualBilling,
    billingModeData.annualBillingData.isCurrentBillingMode,
    dispatchForm,
    monthlyBilling,
    billingModeData.monthlyBillingData.isCurrentBillingMode,
    quarterlyBilling,
    billingModeData.quarterlyBillingData.isCurrentBillingMode,
    semiAnnualBilling,
    billingModeData.semiAnnualBillingData.isCurrentBillingMode,
  ]);
  useEffect(() => {
    dispatchForm({
      type: types.REPLACE_FIELD,
      formField: {
        ...quarterlyBilling,
        label: billingModeData.quarterlyBillingData.frequency,
        isPac: compareStrings(agreementKeyAdmin, "EDS-DI"),
      },
    });
  }, [agreementKeyAdmin]);

  const { openPacToPacModal } = usePacToPacModalProps(props);

  const { isPac: isCurrentBillingModePAC, name: currentBillingModeFieldName } =
    getCurrentBillingFrequencyFormField(formData);
  const {
    isPac: isSelectedBillingModePAC,
    name: selectedBillingModeFieldName,
  } = getSelectedBillFrequency(formData);

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
  }, []);
  useEffect(() => {
    if (
      isCurrentBillingModePAC &&
      isSelectedBillingModePAC &&
      currentBillingModeFieldName !== selectedBillingModeFieldName
    ) {
      if (didMount) {
        openPacToPacModal();
      }
    }
  }, [
    currentBillingModeFieldName,
    isCurrentBillingModePAC,
    isSelectedBillingModePAC,
    selectedBillingModeFieldName,
  ]);

  return {
    formData,
    billingModeData,
  };
};

export const useConfirmationProps = (props) => {
  const { agreementKey } = useParams();
  const { formData } = useFormContext();

  const {
    user: { email, firstName, lastName },
  } = useUserState();

  const { policyId, productTypeName, insured, owners, billingData } = props;
  const { billFrequency } = billingData;
  const { isPac: isCurrentBillingModePAC, label: currentBillingModeLabelName } =
    getCurrentBillingFrequencyFormField(formData);
  const { label: newBillFrequency } = getSelectedBillFrequency(formData);

  const financialProfessionalName = `${firstName} ${lastName}`;
  const financialProfessionalEmail = formData.contactChoiceEmailInput.value;
  const selectedDate = formatDate(
    formData.changeBillingFrequencyStartDate.value?.toString()
  );

  const currentDateAndTime = formatDate(getCurrentDate(), "dateWithTime");
  const timezone = getCurrentDateTimeWithTimezone().split(" ")[3];
  const currentDateTimeAndTimezone = currentDateAndTime.concat(" ", timezone);

  return {
    agreementKey,
    useEmail: email,
    policyId,
    productTypeName,
    insured,
    owners,
    currentBillFrequency: isCurrentBillingModePAC
      ? currentBillingModeLabelName
      : billFrequency,
    newBillFrequency,
    financialProfessionalName,
    financialProfessionalEmail,
    selectedDate,
    currentDateTimeAndTimezone,
  };
};

export const useReviewProps = (props) => {
  const { policyId, productTypeName, insured, owners, billingData } = props;
  const { formData } = useFormContext();
  const modalPremiums = getModalPremiumsForPolicy(props);
  const { billingModeData } = getBillingModeData(modalPremiums);
  const {
    user: { firstName, lastName },
  } = useUserState();
  const { billFrequency } = billingData;
  const { isPac: isCurrentBillingModePAC, label: currentBillingModeLabelName } =
    getCurrentBillingFrequencyFormField(formData);

  const {
    frequency,
    premium,
    annualPremium,
    modalChargeAmount,
    aprPercentage,
  } = getSelectedModalPremium(formData, billingModeData);

  const financialProfessionalName = `${firstName} ${lastName}`;
  const financialProfessionalEmail = formData.contactChoiceEmailInput.value;
  const selectedDate = formatDate(
    formData.changeBillingFrequencyStartDate.value?.toString()
  );

  return {
    policyId,
    productTypeName,
    insured,
    owners,
    currentBillFrequency: isCurrentBillingModePAC
      ? currentBillingModeLabelName
      : billFrequency,
    newBillFrequency: frequency,
    premium,
    annualPremium,
    modalChargeAmount,
    aprPercentage,
    financialProfessionalName,
    financialProfessionalEmail,
    selectedDate,
  };
};

export const useChangeBillingFrequencyAcknowledgementProps = () => {
  const { formData } = useFormContext();

  return { formData };
};
export const useCanGoToPACForm = (props) => {
  const { formData } = useFormContext();
  const { user } = useUserState();
  const { setPageNumber, setError, lineOfBusinessCode, policyId } = props;
  const requestorContactEmail = useRequestorContactEmail();

  const onClickPACForm = () => {
    const { hasErrorForPage1 } = checkErrorForPage1({
      formData,
      setError,
      requestorContactEmail,
    });
    if (!hasErrorForPage1) {
      fireTealiumEvent({
        label: "SVNT",
        action: `${lineOfBusinessCode}: Frequency Change/PAC Removal - go to form`,
        category: "Frequency Change/PAC Removal",
        value: policyId,
        custId: user?.email,
      });
      setPageNumber(4);
    }
  };

  return { onClickPACForm };
};
export const useCanContinue = (props) => {
  const { formData } = useFormContext();
  const { nextPage, pageNumber, setError } = props;
  const requestorContactEmail = useRequestorContactEmail();

  const onContinue = () => {
    if (pageNumber === 1) {
      const { hasErrorForPage1 } = checkErrorForPage1({
        formData,
        setError,
        requestorContactEmail,
      });
      if (!hasErrorForPage1) {
        nextPage();
      }
    } else {
      nextPage();
    }
  };
  return { onContinue };
};

export const checkErrorForPage1 = (props) => {
  const { formData, setError, requestorContactEmail } = props;
  const { hasBillingFrequencyError } = checkForBillingFrequencyError({
    formData,
    setError,
  });
  const { hasRequestorContactError } =
    !hasBillingFrequencyError &&
    checkForRequestorContactError({
      formData,
      setError,
      requestorContactEmail,
    });

  const { hasDatePickerInputError } =
    !hasBillingFrequencyError &&
    !hasRequestorContactError &&
    checkForDatePickerInputError({ formData, setError });
  return {
    hasErrorForPage1:
      hasRequestorContactError ||
      hasBillingFrequencyError ||
      hasDatePickerInputError,
  };
};

export const getSelectedBillFrequency = (formData) => {
  const { monthlyBilling, quarterlyBilling, semiAnnualBilling, annualBilling } =
    formData;
  if (monthlyBilling?.value) return monthlyBilling;
  if (quarterlyBilling?.value) return quarterlyBilling;
  if (semiAnnualBilling?.value) return semiAnnualBilling;
  if (annualBilling?.value) return annualBilling;
  return "";
};

export const getSelectedModalPremium = (formData, billingModeData) => {
  const { monthlyBilling, quarterlyBilling, semiAnnualBilling, annualBilling } =
    formData;

  if (monthlyBilling?.value) return billingModeData.monthlyBillingData;
  if (quarterlyBilling?.value) return billingModeData.quarterlyBillingData;
  if (semiAnnualBilling?.value) return billingModeData.semiAnnualBillingData;
  if (annualBilling?.value) return billingModeData.annualBillingData;
  return {};
};

export const getCurrentBillingFrequencyFormField = (formData) => {
  const { monthlyBilling, quarterlyBilling, semiAnnualBilling, annualBilling } =
    formData;

  if (monthlyBilling?.isCurrentBillingMode) return monthlyBilling;
  if (quarterlyBilling?.isCurrentBillingMode) return quarterlyBilling;
  if (semiAnnualBilling?.isCurrentBillingMode) return semiAnnualBilling;
  if (annualBilling?.isCurrentBillingMode) return annualBilling;
  return {};
};

export const checkForBillingFrequencyError = (props) => {
  const { formData, setError } = props;
  const {
    monthlyBilling,
    quarterlyBilling,
    semiAnnualBilling,
    annualBilling,
    authorizedToChangeBillingFrequency,
  } = formData;

  const isFrequencySelected =
    monthlyBilling.value ||
    quarterlyBilling.value ||
    semiAnnualBilling.value ||
    annualBilling.value;

  const isCurrentSelected =
    (monthlyBilling.value && monthlyBilling.isCurrentBillingMode) ||
    (quarterlyBilling.value && quarterlyBilling.isCurrentBillingMode) ||
    (semiAnnualBilling.value && semiAnnualBilling.isCurrentBillingMode) ||
    (annualBilling.value && annualBilling.isCurrentBillingMode);

  let validationError = null;

  if (!isFrequencySelected) {
    validationError = "Please select a frequency.";
  } else if (isCurrentSelected) {
    validationError =
      "The frequency selected is the same as the current frequency. Please select another frequency.";
  } else if (!authorizedToChangeBillingFrequency.value) {
    validationError =
      "The owner must authorize the change of Billing Frequency before you submit the request. If the owner has authorized this change, select the checkbox and press continue to proceed.  Please contact your Agency Supervisory Officer (ASO) with any questions.";
  }
  setError(validationError);
  return { hasBillingFrequencyError: isDefined(validationError) };
};

export const useChangeBillingFrequencyStartDateProps = (props) => {
  const { billingData } = props;
  const { formData, dispatchForm } = useFormContext();
  const datePickerMinDate = new Date();
  const datePickerMaxDate = new Date().setDate(new Date().getDate() + 30);
  const { changeBillingFrequencyStartDate } = formData;
  const selectedDate = changeBillingFrequencyStartDate?.value;

  const { paymentPreference } = billingData;

  const pacDraftMessage = compareStrings(paymentPreference, "PAC")
    ? "If the PAC draft date is close to the effective date of this change, the draft may still occur."
    : "";

  const dateHandler = (date) => {
    if (isDefined(changeBillingFrequencyStartDate) && isDefined(dispatchForm)) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...changeBillingFrequencyStartDate,
          value: date,
          postpone: false,
          minDate: datePickerMinDate,
          maxDate: datePickerMaxDate,
        },
      });
    }
  };
  return {
    selectedDate,
    datePickerMinDate,
    datePickerMaxDate,
    pacDraftMessage,
    dateHandler,
    invalidDate: datePickerInputValidation({
      selectedDate,
      datePickerMinDate,
      datePickerMaxDate,
    }),
  };
};

export const checkForDatePickerInputError = (props) => {
  const { formData, setError } = props;
  const {
    changeBillingFrequencyStartDate: { value, minDate, maxDate },
  } = formData;

  let validationError = null;
  if (
    datePickerInputValidation({
      selectedDate: value,
      datePickerMinDate: minDate,
      datePickerMaxDate: maxDate,
    })
  ) {
    validationError = "Please fix input errors.";
  }

  setError(validationError);
  return { hasDatePickerInputError: isDefined(validationError) };
};

export const makeChangeBillingFrequencyRequestBody = (props) => {
  const {
    formData,
    agreementKey,
    agreementRequest,
    producerRequest,
    user,
    billingData,
  } = props;

  const {
    data: { agreementKeyAdmin, agreementCustomers, lineOfBusinessCode },
  } = agreementRequest;
  const { producers } = producerRequest?.data || {};

  const isDisabilityPolicyResult = isDisabilityPolicy({ lineOfBusinessCode });

  const isLifeOrDiOpmMprVntg1Admin =
    (compareStrings(lineOfBusinessCode, "LIFE") || isDisabilityPolicyResult) &&
    (compareStrings(agreementKeyAdmin, "OPM") ||
      compareStrings(agreementKeyAdmin, "MPR") ||
      compareStrings(agreementKeyAdmin, "VNTG1"));

  let adminSystem;
  if (compareStrings(agreementKeyAdmin, "OPM")) {
    adminSystem = "OPM";
  } else if (compareStrings(agreementKeyAdmin, "MPR")) {
    adminSystem = "MPR";
  } else if (compareStrings(agreementKeyAdmin, "VNTG1")) {
    adminSystem = "VT1";
  }

  const insuredCustomers =
    removeOwnerAndInsuredDuplicates(getUsers(agreementCustomers, "INSD")) || [];
  const onlyPrimaryInsured = insuredCustomers?.filter(
    (person) => person?.roleSubType === "PRMR"
  );

  const {
    firstName: insuredFirstName = "",
    lastName: insuredLastName = "",
    middleName: insuredMiddleName = "",
  } = onlyPrimaryInsured?.[0] || [];

  const { isAnAgent, idNumber } = getBpIdFromUser(user);

  const { requestorTypeCode } = getRequestorInfo({
    idNumber,
    producerData: producerRequest?.data,
    agreementKey,
  });

  const servicingAgent = getFirstServicingAgent({
    agents: producers,
  });
  const {
    businessPartnerId,
    agencyDoingBusinessAs,
    firstName: servicingAgentFirstName,
    lastName: servicingAgentLastName,
    homeAgencyId,
  } = servicingAgent;

  const receivedDateTime = formatDate(getCurrentDate());
  const requestorName = `${user.firstName} ${user.lastName}`;
  const lengthOfAgencyId = 3;
  const orgUnit = isAnAgent
    ? getLastXChars(homeAgencyId, lengthOfAgencyId).padStart(
        lengthOfAgencyId,
        0
      )
    : "";

  const currentTimeLongCommentFormatted = getCurrentDateTimeWithTimezone();
  const { label: newBillingFrequency } = getSelectedBillFrequency(formData);

  let newBillingFrequencyCode;
  if (compareStrings(newBillingFrequency, "Quarterly")) {
    newBillingFrequencyCode = "Q";
  } else if (compareStrings(newBillingFrequency, "Semi-Annual")) {
    newBillingFrequencyCode = "SA";
  } else if (compareStrings(newBillingFrequency, "Annual")) {
    newBillingFrequencyCode = "A";
  }

  const { billFrequency } = billingData;

  const { isPac: isCurrentBillingModePAC, label: currentBillingModeLabelName } =
    getCurrentBillingFrequencyFormField(formData);

  const currentBillFrequency = isCurrentBillingModePAC
    ? currentBillingModeLabelName
    : billFrequency;

  const formattedEffectiveDate = formatDate(
    formData?.changeBillingFrequencyStartDate?.value.toDateString()
  );

  const longComment = `Contact Info: \r\n  Name: ${requestorName}\r\n  Method: E-mail\r\n  Detail: ${formData.contactChoiceEmailInput.value}\r\n\r\nOperator Name: ${requestorName} ID: ${idNumber} Org Unit: ${orgUnit}\r\nSubmitted Date/Time: ${currentTimeLongCommentFormatted}
  \r\n\r\n Current Frequency: ${currentBillFrequency}  \r\n\r\n New Frequency: ${newBillingFrequency} \r\n\r\n Effective Date:${formattedEffectiveDate} \r\n\r\n`;

  const lifeOrDiOpmMprVntg1AdminLongComment = `Contact Info: \r\nName: ${requestorName}\r\nMethod: E-mail\r\nDetail: ${formData.contactChoiceEmailInput.value}\r\n\r\nOperator Name: ${requestorName} ID: ${idNumber} Org Unit: ${orgUnit}\r\nSubmitted Date/Time: ${currentTimeLongCommentFormatted}  \r\n\r\nRemove from Pre-Authorized Check
    \r\nCurrent Frequency: ${currentBillFrequency}  \r\n\r\nNew billing frequency: ${newBillingFrequencyCode}-${newBillingFrequency}  \r\n\r\nAdmin system:${adminSystem} \r\n\r\nEffective Date:${formattedEffectiveDate} \r\n\r\n`;

  const requestHelper = {};
  requestHelper.transactionName = "billingFrequencyChange";

  const workEventCode = calculateBillingFrequencyChangeType({
    billingData,
    formData,
    isDisabilityPolicyResult,
    agreementKeyAdmin,
    isLifeOrDiOpmMprVntg1Admin,
  });

  const isPacRemovalRPAWorkEvent = compareStrings(workEventCode, "11877");

  let userId;
  if (isPacRemovalRPAWorkEvent) {
    userId = "RPV0824";
  } else if (isDisabilityPolicyResult) {
    userId = "DIINDBILL";
  } else {
    userId = "ESRCSDS";
  }
  let divisionCode = "CS";
  let departmentCode = "DS";
  if (isDisabilityPolicyResult && !isPacRemovalRPAWorkEvent) {
    divisionCode = "DI";
    departmentCode = "DI";
  }
  if (
    isDisabilityPolicyResult &&
    isPacRemovalRPAWorkEvent &&
    compareArrayOfStrings(["OPM", "MPR"], agreementKeyAdmin)
  ) {
    divisionCode = "DI";
    departmentCode = "DI";
  }

  return {
    recordId: agreementRequest?.data?.agreementKeyPrimary,
    priorityCode: "10",
    divCode: divisionCode,
    deptCode: departmentCode,
    agentId: `${businessPartnerId}`,
    insuredFirstName,
    insuredLastName,
    insuredMiddleName,
    completionInd: "N",
    workEvent: workEventCode,
    receivedDate: receivedDateTime,
    userId,
    requestorName,
    requestorTypeCode,
    loggedByIdent: "SVCNET2",
    lastUpdatedBy: "SVCNET2",
    agreementNumber: agreementRequest?.data?.agreementKeyPrimary,
    carrierAdminSystem: agreementKeyAdmin,
    serviceChannelSourceCode: "19",
    shortComment: "Frequency Change Request",
    servicingAgency: `${agencyDoingBusinessAs}`,
    servicingAgentFirstName,
    servicingAgentLastName,
    longComment: isPacRemovalRPAWorkEvent
      ? lifeOrDiOpmMprVntg1AdminLongComment
      : longComment,
    requestHelper,
  };
};

export const calculateBillingFrequencyChangeType = (props) => {
  const {
    billingData,
    formData,
    isDisabilityPolicyResult,
    agreementKeyAdmin,
    isLifeOrDiOpmMprVntg1Admin,
  } = props;
  const { isPac: newFrequencyisPAC } = getSelectedBillFrequency(formData);
  const { billFrequency: currentFrequency, paymentPreference } = billingData;
  const currentFrequencyisPAC =
    (compareStrings(currentFrequency, "Monthly") &&
      compareStrings(paymentPreference, "PAC")) ||
    (compareStrings(currentFrequency, "Quarterly") &&
      compareStrings(agreementKeyAdmin, "EDS-DI"));

  let workEventCode;

  if (currentFrequencyisPAC === true && newFrequencyisPAC === false) {
    if (isLifeOrDiOpmMprVntg1Admin) {
      workEventCode = "11877";
    } else {
      workEventCode = "8439";
    }
  } else if (
    (currentFrequencyisPAC === false && newFrequencyisPAC === false) ||
    (currentFrequencyisPAC === true &&
      newFrequencyisPAC === true &&
      isDisabilityPolicyResult)
  ) {
    workEventCode = "8438";
  } else {
    workEventCode = "No Work Event";
  }

  return workEventCode;
};

export const useModalControlProps = () => {
  const { formData } = useFormContext();
  const { isPac: isCurrentBillingModePAC, name: currentBillingModeFieldName } =
    getCurrentBillingFrequencyFormField(formData);
  const {
    isPac: isSelectedBillingModePAC,
    name: selectedBillingModeFieldName,
  } = getSelectedBillFrequency(formData);

  return {
    isPacSelected:
      isSelectedBillingModePAC &&
      (currentBillingModeFieldName === selectedBillingModeFieldName ||
        !isCurrentBillingModePAC),
  };
};
